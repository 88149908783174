.accordion {
  @include pattern-stack-margin;
}

.accordion__title {
  @include visually-hidden;
}

.accordion__panel {
  max-height: 100em;
  margin: 0;
  padding: component-spacing(2xs) 0;
  transition: visibility 0s $transition-easing, 
    max-height $transition-time $transition-easing, 
    opacity $transition-time $transition-easing;
  opacity: 1;
  overflow: hidden;
  visibility: visible;
}

[aria-hidden='true'].accordion__panel {
  max-height: 0;
  margin: 0;
  padding: 0;
  transition-delay: $transition-time, 0s, 0s;
  opacity: 0;
  visibility: hidden;
}

.accordion__header,
button.accordion__header {
  display: flex;
  position: relative;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: $accent-line-thickness;
  transition: all $transition-time $transition-easing;
  font-family: $font-tertiary;
  font-weight: normal;
  @include rr-font-compute(xl, m);
  text-align: left;
  @extend .ic;
  @extend .ic--plus-circle;
  &::before {
    order: 2;
    display: inline-block;
    transform: rotate(0);
    transform-origin: 50% 50%;
    transition: all $transition-time $transition-easing;
    font-size: rem(30px);
    margin-left: component-spacing(xs);
  }

  &[aria-expanded='true']::before {
    transform: rotate(45deg);
  }
}

// ==============================================
// scheming
@each $scheme-name, $scheme in $schemes {
  @include scheme-pattern($scheme-name, $scheme) {
    button.accordion__header {
      color: color(button-alternate-text, $scheme);
      background-color: color(button-alternate-background, $scheme);
      border-color: color(button-alternate-border, $scheme);
      &:hover,
      &:active,
      &:focus {
        color: color(button-alternate-hover-text, $scheme);
        background-color: color(button-alternate-hover-background, $scheme);
        border-color: color(button-alternate-hover-border, $scheme);
      }
    }
  }
}