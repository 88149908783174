.utility-nav {
  display: flex;
  flex-flow: column nowrap;

  .search {
    form {
      input::-webkit-input-placeholder {
        font-weight: bold;
        @include rr-font-compute(xs, m);
      }
    }
  }


  @include rr-break-directive(l) {
    flex-flow: row nowrap;
    align-items: center;
    .link-list {
      font-weight: bold;
      ul {
        @include clean-list;
        li {
          margin: {
            right: component-spacing(m);
            bottom: 0;
          }
          @include rr-font-compute(xs, m);
          a {
            background-size: 0 0%, 0 0%;
            &:hover,
            &:active,
            &:focus {
              background-size: 100% 0%, 100% 100%;
            }
          }
        }
      }
    }
  }
}


// ==============================================
// scheming
@each $scheme-name, $scheme in $schemes {
  @include scheme-pattern($scheme-name, $scheme) {
    .utility-nav {
      .search {
        form {
          input::-webkit-input-placeholder {
            color: color(link-text, $scheme);
          }
        }
      }
    }
  }
}