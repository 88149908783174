// ==============================================
// css utilities

// generate the image url by prefixing the layout image path
@function image-url($url) {
  @return url('#{$image-url-path}#{$url}');
}

// Proper background image url syntax. Relies on postcss to fix SVG encoding.
@function svg-url($svg) {
  @return url('data:image/svg+xml;utf8,' + $svg);
}

// return a spacing value
@function component-spacing($value: m, $map: $component-spacing) {
  @if map-has-key($map, $value) {
    @return map-get($map, $value);
  }
  @return 0;
}

// max-width breakpoint not provided by the rr-utilities
@mixin break-max($break) {
  @media screen and (max-width: ($break - rem(1px))) {
    @content;
  }
}

// explicit min-width breakpoint helper
// (rr-break-directive takes mq from config)
@mixin break-min($break) {
  @media screen and (min-width: $break) {
    @content;
  }
}

// range breakpoint helper
// expects an explicit break value
@mixin break-range($min-break, $max-break) {
  @media screen and (min-width: $min-break) and (max-width: ($max-break - rem(1px))) {
    @content;
  }
}

// ==============================================
// common pattern utilities

// Strip decorations from unordered, ordered lists
@mixin clean-list {
  margin: 0;
  padding: 0;
  list-style: none;

  & > li {
    margin-left: 0;
    text-indent: 0;

    &::before {
      display: none;
    }
  }
}

// hide an item visually but show for screenreaders
@mixin visually-hidden {
  position: absolute; 
  overflow: hidden; 
  clip: rect(0 0 0 0); 
  height: 1px; width: 1px; 
  margin: -1px; padding: 0; border: 0; 
}

// stack patterns consistently
@mixin pattern-stack-margin($margin: component-spacing(m)) {
  &:not(:last-child) {
    margin-bottom: $margin;
  }
}

// vertically center items in a container
@mixin vertically-center-contents {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  &.grid {
    display: grid;
    align-items: center;
  }
}

// ==============================================
// helpers for specific patterns

// fieldset handling for checkbox and radio groups
@mixin fieldgroup {

  // pretty highlighting when the field is marked error or valid
  border: $form-field-border-size transparent solid;
  padding: 0 $form-field-spacing $form-field-spacing $form-field-spacing;
  margin-left: -($form-field-spacing);
  margin-right: -($form-field-spacing);
  
  legend {
    padding: 0 $form-field-spacing;
  }
  ul {
    @include clean-list;
  }
  label {
      font-weight: normal;
      cursor: pointer;
  }
}

// fake fancy underline for links: mechanics
// call this outside the scheme,
// so the settings can be overwritten by parent patterns
@mixin faux-underline-mechanics($underline-height: $accent-line-thickness) {
  background-repeat: repeat-x;
  background-position: 0 100%, 0 100%;
  background-size: 100% $underline-height;
  transition: background-size $transition-time $transition-easing, color $transition-time $transition-easing;
  &:hover,
  &:active,
  &:focus {
    // leave the "underline" in place, scale up the highlight
    background-size: 100% 0%, 100% 100%;
  }
}
// fake fancy underline for links: image/color only
// call this inside the scheme loop.
// to unset/remove, pass $underline-color: transparent
// when unsetting, you may want to pass $indicate-action: true,
// if the other styles on the link you're unsetting don't indicate hover/active/focus
@mixin faux-underline($underline-color: color(undefined), $underline-hover-color: transparent, $indicate-action: false) {
  background-image:
    linear-gradient($underline-color 0%, $underline-color 100%),
    linear-gradient($underline-hover-color 0%, $underline-hover-color 100%);
  @if $indicate-action {
    &:hover,
    &:active,
    &:focus {
      text-decoration: underline;
    }
  }
}

// when slabs are nested within one another, adjust padding
@mixin nested-slab-padding {
  padding-right: component-spacing(s);
  padding-left: component-spacing(s);
  @include rr-break-directive(m) {
    padding-right: component-spacing(m);
    padding-left: component-spacing(m);
  }
}

// when boxes are nested within one another, adjust padding
// (usually only if schemed)
@mixin nested-box-padding($square: false) {
  // if this is a forced-square-ratio box,
  // simulate this padding with a margin on the inner/wrapper item instead
  // only when the square is actually active (killed on smaller screens)
  @if $square {
    @include break-max($break-square) {
      padding: component-spacing(s);
    }
    & > * {
      @include break-min($break-square) {
        margin: component-spacing(s);
      }
      @include rr-break-directive(l) {
        margin: component-spacing(m);
      }
    }
  // normal/non-square boxes just get padding
  } @else {
    padding: component-spacing(s);
    @include rr-break-directive(l) {
      padding: component-spacing(m);
    }
  }
}

// ==============================================
// float and breakout float utilities

// calculate the width of a floating item
// this could be rewritten to be more friendly - the cases are fragile
@function float-width($float-constraint, $gutter, $max, $container) {
  @if $max and $container {
    @return calc((#{$float-constraint} - #{$gutter}) + ((#{$max} - #{$container})/4));   
  } @else {
    @return calc(#{$float-constraint - #{$gutter}});
  }
}

// calculate the offset margin of a float item
// when it's allowed to break out of its container
@function float-breakout-margin($gutter, $max, $container) {
  @if $max {
    @return calc(((#{-$max} - #{-$container})/4) - #{$gutter});
  } @else {
    @return calc((#{-$container}/4) - #{$gutter});
  }
}

// ==============================================
// .grid utilities

// calculate grid-gap for .grid when using flexbox fallback
@function grid-gap-fallback($gap) {
  @return $gap/2;
}

// calculate grid cell size for .grid when using flexbox fallback
// $cell: integer for number of columns (for even cols) 
//        OR percentage for cell size (for uneven cols)
// $gap: grid-gap for the grid
@function grid-cell-fallback($cell, $gap) {
  $cell-size: 0;
  @if is-length($cell) or is-percentage($cell) {
    $cell-size: $cell;
  } @else {
    $cell-size: percentage(1/$cell);
  }
  @return calc(#{$cell-size} - #{grid-gap-fallback($gap)*2});
}

// calculate the smaller/minor cell size for uneven grids
// $cell: the larger/major cell size
@function uneven-grid-remainder($cell) {
  @return 100 - $cell;
}

// generate fake grid-gap/grid gutters from margins
// for .grid when using flexbox fallback
@mixin grid-gutter-fallback($gap, $grid-bottom-margin: component-spacing(m)) {
  margin: -(grid-gap-fallback($gap)) {
    bottom: $grid-bottom-margin;
  }
  &:last-child {
    margin-bottom: -(grid-gap-fallback($gap));
  }
  > * {
    margin: grid-gap-fallback($gap);
  }
  // unset these margin tweaks if grids are supported,
  // since grid-gap handles all that layout
  @supports(display: grid) {
    margin: {
      top: 0;
      right: 0;
      left: 0;
    }
    > * {
      width: auto;
      min-width: 0;
      margin: 0;
    }
  }
}

// scale a fixed grid variant
// assumes basic grid defaults are defined
@mixin fixed-grid($columns, $gap) {
  > * {
    flex-basis: grid-cell-fallback($columns, $gap);
  }
  @supports(display: grid) {
    grid-template-columns: repeat($columns, 1fr);
  }
}

// scale a flexible grid variant
// assumes basic grid defaults are defined
@mixin flexible-grid($cell-min) {
  // flexbox fallback first
  > * {
    flex-basis: $cell-min;
    min-width: $cell-min;
  }
  @supports(display: grid) {
    // build grid
    grid-template-columns: repeat(auto-fit, minmax($cell-min, 1fr));
    > * {
      // override fallback
      min-width: 0;
    }
  }
}

// ruled grid helpers
@mixin ruled-grid-line($thickness: $accent-line-thickness, $gap: $grid-gap, $horizontal: true) {
  display: inline-block;
  content: '';
  position: absolute;

  @if $horizontal {
    left: 0;
    right: 0;
    top: auto;
    bottom: -(($gap/2) + $thickness);
    height: $thickness;
    width: 100%;
  } @else {
    top: 0;
    bottom: 0;
    left: auto;
    right: -(($gap/2) + $thickness);
    width: $thickness;
    height: 100%;
  }
}


// ==============================================
// common typography styles

@mixin text-small-bold {
  @include rr-font-compute(xs, m);
  text-transform: uppercase;
  font-weight: bold;
  font-family: $font-primary;
}

@mixin text-tertiary($scale: true) {
  @if $scale {
    @include rr-font-compute(s, m);
  }
  font-family: $font-tertiary;
  text-transform: uppercase;
  font-weight: 500;
}

@mixin text-simple-heading($children: true) {
  font-family: $font-primary;
  font-weight: bold;
  @include rr-font-compute(l, m);
  @if $children {
    a {
      font-weight: bold;
      background-position: 100% 90%;
    }
  }
}


// ==============================================
// ornamental 

// floating accent lines that appear below headings, etc
// line color should come from scheme - not here
@mixin floating-line($property: 'after') {
  &:#{$property} {
    content: '';
    display: block;
    height: $floating-line-thickness;
    width: $floating-line-width;
    @if $property == 'after' {
      margin-top: component-spacing(s);
    } @else {
      margin-bottom: component-spacing(s);
    }
  }
}

// trailing slash after breadcrumb items, meta, taxonomies, etc
@mixin slash-after($spacing: component-spacing(2xs)) {
  &::after {
    content: '/';
    font-weight: bold;
    margin-left: $spacing;
    margin-right: $spacing;
  }

  &:last-child::after {
    display: none;
  }
}

// textures for slabs and boxes
// grid pattern
@mixin texture-grid() {
  position: relative;
  & > * {
    position: relative;
    z-index: 2;
  }
  &:after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
	  background-repeat: repeat;
    background-position: center center;
    // this can be overwritten by scheme if the invert is needed
    background-image: image-url('texture-grid.svg');
  }
}

// facet/triangle image
@mixin texture-facet() {
  background-image: image-url('texture-facet.jpg');
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}