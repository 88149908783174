.pager {
  ul {
    display: flex;
    flex-flow: row wrap;
    padding-left: 0;
    margin-bottom: component-spacing(xs);
    @include rr-break-directive(l) {
      flex-flow: row nowrap;
    }
    li.pager__item {
      display: block;
      margin-right: (component-spacing(2xs)/2);
      flex: 1 1 100%;
      @media (min-width: $break-xsmall) {
        flex: 0 1 auto;
      }
      &:last-child {
        @media (min-width: $break-xsmall) {
          margin-right: 0;
        }
      }
      a.button,
      button {
        display: block;
        text-align: center;
        font-weight: normal;
      }
    }
    li.pager__item--first,
    li.pager__item--previous,
    li.pager__item--next,
    li.pager__item--last {
      a.button,
      button {
        @include text-small-bold;
      }
    }
    li.pager__item.is-active {
      a.button,
      button {
        font-weight: bold;
      }
    }
  }

  &.pager--center {
    ul {
      justify-content: center;
    }
  }
}

// ==============================================
// scheming
@each $scheme-name, $scheme in $schemes {
  @include scheme-pattern($scheme-name, $scheme) {
    .pager {
      a.button,
      button {
        color: color(button-background, $scheme);
        background-color: color(background, $scheme);
        border-color: color(background, $scheme);
        &:link,
        &:visited {
          color: color(link-text, $scheme);
        }
        &:hover,
        &:active,
        &:focus {
          color: color(button-hover-text, $scheme);
          background-color: color(button-hover-background, $scheme);
          border-color: color(background, $scheme);
        }
      }
      li.pager__item.is-active {
        a.button,
        button {
          color: color(text, $scheme);
          background-color: color(shade-background, $scheme);
          border-color: color(background, $scheme);
          &:hover,
          &:active,
          &:focus {
            border-color: color(background, $scheme);
          }
        }
      }
    }
  }
}


// ==============================================
// pager inside a sidebar layout
.grid.sidebar .grid__sidebar {
  .pager {
    ul {
      @include rr-break-directive(l) {
        flex-flow: row wrap;
      }
      li.pager__item {
        flex: 1 1 100%;
        margin-right: 0;
      }
    }
  }
}