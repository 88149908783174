/* This file has been generated from a template. Do not edit it directly, your edits will 
   be ignored like a ten-year-old's dad. Instead, edit the source template in 
   /assets/font-svg/_iconfont-fontface.scss. 

   This is a lodash template.
*/

@font-face {
    font-family: "iconfont";
    src: url('../fonts/icons-862743408.eot');
    src: url('../fonts/icons-862743408.eot?#iefix') format('eot'), url('../fonts/icons-862743408.woff2') format('woff2'), url('../fonts/icons-862743408.woff') format('woff'), url('../fonts/icons-862743408.ttf') format('truetype'), url('../fonts/icons-862743408.svg#icons-862743408') format('svg');
    font-weight: normal;
    font-style: normal;
}