.box.testimonial {
  .box__content {
    @include rr-break-directive(m) {
      display: flex;
      flex-flow: column nowrap;
      align-content: flex-start;
    }
    .link-list {
      @include break-min($break-medium) {
        margin-left: auto;
      }
      @include rr-break-directive(l) {
        margin-top: auto;
      }
    }
  }
  .box__footer {
    position: relative;
    & > figure {
      margin-bottom: 0;
      @include rr-break-directive(l) {
        // @supports (object-fit: cover) {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          margin: auto;
          img {
            font-family: "object-fit: cover; object-position: center";
            object-fit: cover;
            object-position: center;
          }
        // }
      }
    }
  }

  &[class*=scheme--] {
    .box__footer {
      @include rr-break-directive(l) {
        margin: -(component-spacing(m));
        margin-left: 0;
      }
    }
  }
}

// ==============================================
// testimonial inside a sidebar layout
.grid.sidebar .grid__sidebar {
  .box.testimonial {
    .box__footer {
      & > figure {
        position: static;
      }
    }
    &[class*=scheme--] {
      .box__footer {
        @include rr-break-directive(l) {
          margin: -(component-spacing(m));
          margin-top: 0;
        }
      }
    }
  }
}