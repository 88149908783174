select {
    appearance: none;
    // keep long options from causing overflow
    // use flex to override this, sorry.
    width: 100% !important;

    // custom down arrow
    // darkest-gray/color-text angle down
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIyLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zOmJ4PSJodHRwczovL2JveHktc3ZnLmNvbSIKCSB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDEwMDAgMTAwMCIKCSBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxMDAwIDEwMDA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojMjUyNTI1O30KPC9zdHlsZT4KPHBvbHlnb24gYng6b3JpZ2luPSIwLjUgMC40OTY0NTIiIGNsYXNzPSJzdDAiIHBvaW50cz0iNDk5LjgsODAyLjEgMTAwMCwzMDIuNCA4OTUuNSwxOTcuOSA1MDAsNTkzLjMgMTA0LjYsMTk3LjkgMCwzMDIuNCAiPgo8L3BvbHlnb24+Cjwvc3ZnPgo=);
    background-origin: content-box;
    background-position: right -1.4rem center;
    background-repeat: no-repeat;
    background-size: 1rem;
    padding-right: 2rem;

    &.select--error {
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIyLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zOmJ4PSJodHRwczovL2JveHktc3ZnLmNvbSIKCSB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDEwMDAgMTAwMCIKCSBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxMDAwIDEwMDA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojRUIwMzAzO30KPC9zdHlsZT4KPHBvbHlnb24gYng6b3JpZ2luPSIwLjUgMC40OTY0NTIiIGNsYXNzPSJzdDAiIHBvaW50cz0iNDk5LjgsODAyLjEgMTAwMCwzMDIuNCA4OTUuNSwxOTcuOSA1MDAsNTkzLjMgMTA0LjYsMTk3LjkgMCwzMDIuNCAiPgo8L3BvbHlnb24+Cjwvc3ZnPgo=);
    }

    &.select--valid {
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIyLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zOmJ4PSJodHRwczovL2JveHktc3ZnLmNvbSIKCSB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDEwMDAgMTAwMCIKCSBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxMDAwIDEwMDA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojNTVCMzdDO30KPC9zdHlsZT4KPHBvbHlnb24gYng6b3JpZ2luPSIwLjUgMC40OTY0NTIiIGNsYXNzPSJzdDAiIHBvaW50cz0iNDk5LjgsODAyLjEgMTAwMCwzMDIuNCA4OTUuNSwxOTcuOSA1MDAsNTkzLjMgMTA0LjYsMTk3LjkgMCwzMDIuNCAiPgo8L3BvbHlnb24+Cjwvc3ZnPgo=);
    }

    &[disabled] {
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIyLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zOmJ4PSJodHRwczovL2JveHktc3ZnLmNvbSIKCSB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDEwMDAgMTAwMCIKCSBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxMDAwIDEwMDA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojNzY3Njc2O30KPC9zdHlsZT4KPHBvbHlnb24gYng6b3JpZ2luPSIwLjUgMC40OTY0NTIiIGNsYXNzPSJzdDAiIHBvaW50cz0iNDk5LjgsODAyLjEgMTAwMCwzMDIuNCA4OTUuNSwxOTcuOSA1MDAsNTkzLjMgMTA0LjYsMTk3LjkgMCwzMDIuNCAiPgo8L3BvbHlnb24+Cjwvc3ZnPgo=);
    }

}

label.select--hide-label {
    @include visually-hidden;
}

select.select--narrow {
    @include break-min($break-medium) {
        max-width: 60%;
    }
}