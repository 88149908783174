a {
  text-decoration: none;
  position: relative;
  z-index: 1;
  font-weight: bold;

  // presets needed for scheming of faux-underline,
  // separate from the scheme so they can be overwritten.
  @include faux-underline-mechanics;

  &.link--fancy,
  &.link--arrow,
  &.link--arrow-reverse,
  &[class*=link--fancy-arrow] {
    display: inline-flex;
    vertical-align: sub;
    @extend .ic;
    @extend .ic--arrow-circle-right;
    &:before {
      margin-right: rem(8px);
      // arrows are a little bigger than the link's base text
      font-size: 1.4em;
    }
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }
    // TBD need to suppress arrow if the fancy link has an icon
    .icon {
      .icon__glyph {
        font-size: 1.4em;
        line-height: 1em;
      }
    }
  }

  &.link--fancy-arrow-reverse,
  &.link--arrow-reverse {
    &:before {
      transform: rotate(180deg);
      // rotate makes the reversed arrows ride lower on the baseline, so:
      align-self: flex-start;
    }
  }

  &.link--extra-fancy,
  &.link--extra-fancy-large {
    text-transform: uppercase;
    &.link--arrow,
    &.link--arrow-reverse {
      @extend .ic--arrow-right;
      &:before {
        font-size: 0.8em;
        line-height: 1.6;
      }
    }
  }

  &.link--extra-fancy-large {
    text-transform: none;
    @include rr-font-compute(2xl, m);
    &:before {
      line-height: 1.8;
    }
  }

  &.link--hidden-icon-label {
    .icon__label {
      @include visually-hidden;
    }
  }

  // special icon cases to show the faux-underline on the icon's label
  &.link--external {
    .icon__label {
      // @include faux-underline-mechanics;
      // don't do this actually because the icon displays flex, so the line doesn't wrap.
      // if we need the combo, you'll have to write the icon without flex
    }
  }
}

// ==============================================
// scheming
@each $scheme-name, $scheme in $schemes {
  @include scheme-pattern($scheme-name, $scheme) {
    a {
      color: color(link-text, $scheme);
      @include faux-underline(color(link-underline, $scheme), color(link-hover-underline, $scheme));
      &:link,
      &:visited {
        color: color(link-text, $scheme);
      }
      &:hover,
      &:active,
      &:focus {
        color: color(link-hover-text, $scheme);
      }
      &.link--fancy,
      &.link--arrow,
      &.link--arrow-reverse,
      &[class*=link--fancy-arrow],
      &.link--hidden-icon-label,
      &.link--external,
      &.link--no-underline {
        @include faux-underline($underline-color: transparent);
      }
      // &.link--external {
      //   .icon__label {
      //     @include faux-underline(color(link-underline, $scheme), color(link-hover-underline, $scheme));
      //   }
      // }
    }
  }
}
