.slab {
  padding: {
    top: component-spacing(s);
    bottom: component-spacing(s);
    left: component-spacing(s);
    right: component-spacing(s);
  }
  @include rr-break-directive(m) {
    padding-top: component-spacing(l);
    padding-bottom: component-spacing(l);
  }
  @include rr-break-directive(xl) {
    padding-left: 0;
    padding-right: 0;
  }
}

.slab__wrapper {
  @include rr-slab-wrapper;
}

// narrow & narrow-center
@include rr-break-directive(l) {
  .slab.slab--narrow,
  .slab.slab--narrow-center {
    .slab__wrapper {
      .slab__content {
        max-width: $grid-uneven-major-size;
      }
    }
  }
  .slab.slab--narrow-center {
    .slab__wrapper {
      .slab__content {
        margin: 0 auto;
      }
    }
  }
}

// texture options
.slab.slab--texture-grid {
  @include texture-grid;
}

.slab.slab--texture-facet {
  @include texture-facet;
}

// ==============================================
// pretty adjacent slabs
// two cases:
// implicit - slabs with no explicit scheme defined fallback to the parent scheme (usually from body/scheme--white)
// explicit - adjacent slabs with explicit slab classes added (ie several rows of scheme--off-white, etc)

// implicit
.slab:not([class*='scheme--']):not(.page-header) + .slab:not([class*='scheme--']) {
  padding-top: 0;
}

// explicit
@each $name, $scheme in $schemes {
  .slab.scheme--#{$name} + .slab.scheme--#{$name} {
    padding-top: 0;
  }
}

// when a schemed slab follows the page header, bump it down
.page-header + .slab[class*='scheme--'] {
  margin-top: component-spacing(s);
  @include rr-break-directive(m) {
    margin-top: component-spacing(l);
  }
}

// ==============================================
// pretty nested slabs
@each $name, $scheme in $schemes {
  // schemed slab inside another schemed slab, but the scheme is not the same
  .slab.scheme--#{$name} .slab[class*='scheme--']:not(.scheme--#{$name}) {
    @include nested-slab-padding;
  }
}

// ==============================================
// slabs inside a sidebar layout:
// this supports ONE LEVEL of nesting slabs inside sidebars,
// which technically should not happen at all (use block instead),
// but always does somehow happen - so we're squashing it here SOMEWHAT.
// any nesting beyond this is a bad cms approach and should be corrected.
.grid.sidebar {
  .grid__main,
  .grid__sidebar {
    & > .slab {
      padding-left: 0;
      padding-right: 0;
    }
    & > .slab[class*='scheme--']:not(.scheme--white) {
      @include nested-slab-padding;
    }
  }
}

// specialty alert slabs for emergency alerts at the top of the page.
// the logo container intentionally overflows in larger screen sizes, so 
// this keeps the alert on top of that container.
.slab.slab--alert {
  position: relative;
  z-index: 10;
}

// ==============================================
// slab scheming
// @each $scheme-name, $scheme in $schemes {
//   @include scheme-pattern($scheme-name, $scheme, 'slab') {
//     // background: color(background, $scheme);
//   }
// }