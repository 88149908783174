table {
  border-collapse: collapse;

  th,
  td {
    padding: component-spacing(xs);
  }

  th:first-child {
    text-align: left;
  }

  thead {
    tr:last-child {
      th {
        border-bottom-width: $accent-line-thickness*2;
        border-bottom-style: solid;
      }
    }
  }

  tbody {
    tr {
      border-bottom-width: $accent-line-thickness;
      border-bottom-style: solid;
    }
  }

  caption {
    padding: {
      top: component-spacing(xs);
      bottom: component-spacing(xs);
    }
    font-style: italic;
    caption-side: bottom;
  }
}

.table-wrapper.table-wrapper--overflow {
  table {
    max-width: 100%;
    overflow: auto;
  }
}

.table-wrapper.table-wrapper--columnize {
  table {
    width: 100%;

    thead {
      display: none;
    }

    th,
    td {
      display: block;
      border-width: 0;
      &.align-right {
        text-align: initial;
      }
    }
  }

  .responsive-label {
    display: inline;
    font-weight: 500;
    &::after {
      content: ': ';
    }
  }

  @supports (display: grid) {
    table {
      tr {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        grid-gap: component-spacing(3xs);
        th {
          grid-column: 1 / -1;
        }
      }
    }
  }

  @include rr-break-directive(l) {
    table {
      thead {
        display: table-header-group;
      }
      tr {
        display: table-row;
      }
      th,
      td {
        display: table-cell;
        padding-top: component-spacing(xs);
        padding-bottom: component-spacing(xs);
        border-width: 4px;

        &.align-right {
          text-align: right;
        }
      }
    }
    .responsive-label {
      display: none;
    }
  }
}


// ==============================================
// scheming
@each $scheme-name, $scheme in $schemes {
  @include scheme-pattern($scheme-name, $scheme) {
    table {
      thead {
        tr {
          &:last-child {
            th {
              border-bottom-color: color(utility-line, $scheme);
            }
          }
        }
      }
      tbody {
        tr {
          border-bottom-color: color(utility-line, $scheme);
        }
      }
      &.table--zebra {
        tbody {
          tr:nth-child(odd) {
            background-color: color(shade-background, $scheme);
          }
        }
      }
    }
  }
}

// ==============================================
// tables in sidebars
// tables should not really be needed or allowed in sidebar columns
// but in case one should accidentally end up there,
// prevent it from breaking the page - ASSUMING it's wrapped in .table-wrapper.
// if tht wrapper isn't possible, then it's probably safest to prevent tables in sidebars completely.
.grid.sidebar .grid__sidebar {
  .table-wrapper {
    max-width: $global-width - $grid-gap - $sidebar-major-size;
    overflow: hidden;
  }
}