dl {
  margin-top: 0;
  margin-bottom: component-spacing(m);
  dt {
    @include rr-font-compute(s, m);
    font-weight: 700;
  }
  dd {
    @include rr-font-compute(s, m);
    margin-bottom: $list-regular;
    margin-left: 0;
  }

  &.list--loose {
    dt {
    }
    dd {
      margin-bottom: $list-loose;
    }
  }
  &.list--tight {
    dt {
      margin-bottom: 0;
    }
    dd {
      margin-bottom: $list-tight;
    }
  }
  &.list--invisible-terms {
    dt {
      display: none;
    }
  }

  &.list--inline-terms {
    dt {
      display: inline;
      @include rr-font-compute(s, m);
      &:after {
        content: ':';
      }
    }
    dd {
      display: inline;
      &:after {
        content: "";
        display: block;
        margin-bottom: $list-regular;
      }
    }
    &.list--loose {
      dd {
        &:after {
          margin-bottom: $list-loose;
        }
      }
    }
    &.list--tight {
      dd {
        &:after {
          margin-bottom: $list-tight;
        }
      }
    }
  }
}
