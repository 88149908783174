
// specific grid nesting tweak to prevent ugly overlap in ie11
.collection {
  .grid.grid--large > .testimonial.grid.grid--narrow-gutters {
    margin: ($grid-gap/2);
    @supports(display: grid) {
      margin: 0;
    }
    @include rr-break-directive(m) {
      flex-basis: calc(50% - (#{$grid-gap}*2));
      @supports(display: grid) {
        grid-gap: $grid-gap-narrow;
      }
    }
  }
}