.fast-fact {
  .fast-fact__stat {
    .headline-group {
      @include floating-line;
      // slightly tighter spacing on the floating line here than in other places
      &:after {
        margin-top: component-spacing(xs);
      }
      .headline-group__head {
        @include rr-font-compute(4xl, m);
        font-family: $font-tertiary;
      }
      // sub and super match in fast fact to allow a complete thought or sentence
      // to be expressed through the full headline group, in a single typeface
      .headline-group__sub {
        @include text-tertiary;
        margin-top: component-spacing(2xs);
      }
    }
  }
  .fast-fact__description,
  .fast-fact__description p {
    @include rr-font-compute(xs, m);
    &:last-child {
      margin-bottom: 0;
    }
  }

  &.fast-fact--simple {
    .fast-fact__stat {
      .headline-group {
        &:after {
          display: none;
        }
        .headline-group__head {
          @include text-simple-heading(true);
          margin-bottom: component-spacing(xs);
        }
      }
    }
  }
}

// ==============================================
// scheming
@each $scheme-name, $scheme in $schemes {
  @include scheme-pattern($scheme-name, $scheme) {
    .fast-fact .fast-fact__stat .headline-group {
      .headline-group__head {
        color: color(accent-text, $scheme);
      }
      &:after {
        background-color: color(accent-line-secondary, $scheme);
      }
    }
    .fast-fact.fast-fact--simple .fast-fact__stat .headline-group {
      .headline-group__head {
        color: color(text, $scheme);
      }
    }
  }
}