.toggle-button__demo {
  margin-top: rr-gridbase(4);
  margin-bottom: rr-gridbase(4);

  &[aria-hidden='true'] {
    display: none;
  }
}

.toggle-button.toggle-button--dropdown {
  position: relative;
  .icon {
    .icon__glyph {
      position: absolute;
      left: auto;
      right: 0;
      top: 0;
      bottom: 0;
      margin: 0;
      height: auto;
      padding: 0 component-spacing(s);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: rem(15px);
      background-color: rgba(#000, 0.3);
      transition: background-color $transition-time $transition-easing;
      &:before {
        transition: transform $transition-time $transition-easing;
      }
    }
  }

  &.toggle-button--dropdown-active {
    .icon {
      .icon__glyph {
        &:before {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.toggle-button.toggle-button--fancy {
  padding: component-spacing(s) component-spacing(m);
  .icon {
    .icon__label {
      @include rr-font-compute(xl, m);
    }
  }
}