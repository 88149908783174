.box.teaser {
  .box__content {
    .teaser__content {
      .meta {
        border-bottom-style: solid;
        border-bottom-width: $accent-line-thickness;
        // border color from scheme
        padding-bottom: component-spacing(2xs);
        margin-bottom: component-spacing(xs);
        time {
          @include text-small-bold;
        }
      }
      .teaser__summary {
        p {
          @include rr-font-compute(s, m);
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .box__footer {
    overflow: hidden; // ie11 height fix when teaser is in a grid
    .teaser__image {
      overflow: hidden; // ie11 height fix when teaser is in a grid
      @include rr-break-directive(m) {
        margin-bottom: component-spacing(m);
      }
      a {
        display: block;
      }
    }
  }

  // if there's no grid/two-col, put the image first on larger screens
  &:not([class*="grid"]) {
    @include rr-break-directive(m) {
      display: flex;
      flex-flow: column nowrap;
      & > .box__content {
        order: 2;  
      }
      & > .box__footer {
        order: 1;
      }
    }
    @include break-max($break-medium) {
      & > .box__content {
        margin-bottom: component-spacing(m);
      }
    }
  }

  // if this is a grid/two-column teaser, vertically center the contents
  // and put the image on the left on larger screens
  &[class*="grid"]:not(.grid--xlarge) {
    @include rr-break-directive(m) {
      & > .box__content {
        order: 2;
        @include vertically-center-contents;
      }
      & > .box__footer {
        order: 1;
        @include vertically-center-contents;
        .teaser__image {
          margin-bottom: 0;
        }
      }
    }
  }
}

// ==============================================
// scheming
@each $scheme-name, $scheme in $schemes {
  @include scheme-pattern($scheme-name, $scheme) {
    .teaser {
      .teaser__content {
        .meta {
          border-bottom-color: color(utility-line, $scheme);
        }
      }
    }
  }
}