footer.footer {
  $logo-max: rem(200px);
  .footer__logo {
    margin-bottom: component-spacing(s);
    max-width: $logo-max;
    a {
      display: block;
      @include faux-underline($underline-color: transparent);
      svg {
        max-width: 100%;
      }
    }
  }
  .link-list.footer__navigation, {
    ul {
      @include clean-list;
      column-count: 2;
      @media (max-width: $break-2xsmall) {
        column-count: 1;
      }
    }
    a {
      @include rr-font-compute(xs, m);
    }
  }
  .link-list.social-media-links {
    margin-top: component-spacing(s);
    margin-bottom: component-spacing(s);
  }
  .link-list.link-list--audience {
    margin-bottom: component-spacing(s);
    .grid {
      margin-bottom: 0;
    }
  }

  .footer__main {
    @include rr-break-directive(m) {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      .footer__logo {
        flex: 0 1 $logo-max;
      }
      .link-list--audience {
        flex: 0 1 100%;
      }
      .nav-and-socials {
        flex: 1;
        margin-left: $grid-gap*1.5;
      }
    }

    @include rr-break-directive(l) {
      flex-flow: row nowrap;
      .nav-and-socials {
        margin-right: $grid-gap;
      }
      .link-list.footer__navigation {
        ul {
          column-count: 3;
        }
      }
      .link-list.link-list--audience {
        flex: 0 1 33%;
        .grid > * {
          display: flex;
          a {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
    @include rr-break-directive(xl) {
      .nav-and-socials {
        margin-right: $grid-gap*1.5;
        margin-left: $grid-gap*1.5;
      }
    }
  }

  .footer__ribbon {
    border-top-width: $accent-line-thickness;
    border-top-style: solid;
    .copyright {
      margin-bottom: 0;
      @include rr-font-compute(2xs, m);
    }
  }
}


@each $scheme-name, $scheme in $schemes {
  @include scheme-pattern($scheme-name, $scheme) {
    .footer__main {
      .link-list.footer__navigation {
        a {
          color: color(link-alternate-text, $scheme);
          &:link,
          &:visited {
            color: color(link-alternate-text, $scheme);
          }
          &:hover,
          &:active,
          &:focus {
            color: color(link-hover-text, $scheme);
          }
        }
      }
    }
    .footer__ribbon {
      border-top-color: color(utility-line, $scheme);
    }
  }
}