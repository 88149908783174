@each $tag, $config in $heading-styles {
  #{$tag},
  .visually-#{$tag} {
    @include rr-heading($tag, $heading-styles);
    margin-top: 0;
    margin-bottom: rr-gridbase(2);
  }
}

h1, h2, h3, h4, h5, h6 {
  a {
    font-weight: normal;
  }

  &.heading--fancy {
    @include floating-line;
  }

  &.heading--simple {
    @include text-simple-heading;
  }

  &.heading--tertiary {
    @include text-tertiary;
  }

  &.heading--fancy-tertiary {
    @include floating-line;
    font-weight: 500;
    font-family: $font-tertiary;
  }

  &.heading--ruled,
  &.heading--ruled-center {
    position: relative;
    display: flex;
    align-items: center;
    @include text-tertiary;
    &:before,
    &:after {
      content: '';
      display: inline-block;
      height: $accent-line-thickness;
      flex: 1 1 auto;
    }
    &:before {
      margin-right: 0.5em;
    }
    &:after {
      margin-left: 0.5em;
    }
  }
  &.heading--ruled {
    &:before {
      display: none;
    }
  }

  // stack circle icons when they are paired with a heading
  .icon--circle {
    flex-flow: column nowrap;
    align-items: flex-start;
    .icon__glyph {
      margin-right: 0;
      margin-top: 0.5em;
      margin-bottom: 0.8em;
    }
  }
}

// linked headings using the secondary font: tweak the faux-underline position.
// support nonsemantic headlines too - technically this should go with the headline group styles,
// but keeping it here to avoid repeating or unnecessary mixins.
// HOWEVER if you need to add additional patterns/selectors to this style,
// just make the mixin.
h1,
.headline-group--h1 {
  a {
    background-position: 100% 80%;
  }
}
h2,
.headline-group--h2 {
  a {
    background-position: 100% 83%;
  }
}
h3,
.headline-group--h3 {
  a {
    background-position: 100% 85%;
  }
}

// special tweaks for the external link icon when in heading context
h1, h2, h3 {
  a.link--external {
    .icon {
      align-items: flex-start;
      .icon__glyph {
        font-size: 0.8em;
        margin-top: rem(2px);
      }
    }
  }
}

// ==============================================
// scheming
@each $scheme-name, $scheme in $schemes {
  @include scheme-pattern($scheme-name, $scheme) {
    h1, h2, h3, h4, h5, h6 {
      &.heading--fancy {
        &:after {
          background-color: color(accent-line, $scheme);
        }
      }
      &.heading--fancy-tertiary {
        &:after {
          background-color: color(accent-line-secondary, $scheme);
        }
      }
      &.heading--ruled,
      &.heading--ruled-center {
        &:before,
        &:after {
          background-color: color(utility-line, $scheme);
        }
      }
    }
  }
}