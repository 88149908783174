label,
legend {
  display: block;
  margin-bottom: component-spacing(2xs);
  font-weight: bold;
  @include rr-font-compute(s, m);
}

// make all the boxes look the same & override browser defaults
input,
textarea,
select {
  border-width: 1px;
  border-style: solid;
  border-radius: 0;
  padding: component-spacing(2xs) component-spacing(xs);
  @include rr-font-compute(s, m);
}


// required fields
label[class*="--required"],
fieldset[class*="--required"] legend {
  &:after {
    content: '*';
    font-weight: bold;
    @include rr-font-compute(2xl, s);
    line-height: 0;
    margin-left: 5px;
    vertical-align: middle;
  }
}


// disabled fields
input,
select,
textarea,
fieldset,
fieldset.radio,
fieldset.checkboxes,
label {

  &[disabled],
  &[class*="--disabled"] {
    cursor: not-allowed;

    label,
    legend {
      cursor: not-allowed;
    }
  }
}


// error and valid states
input,
select,
textarea,
fieldset,
fieldset.radio,
fieldset.checkboxes {
  transition: border $transition-time $transition-easing;

  &[class*="--error"],
  &[class*="--valid"] {
    border-width: $form-field-border-size;
  }
}

label,
legend {
  transition: $transition-time color $transition-easing;
}


// ==============================================
// colors

@each $scheme-name, $scheme in $schemes {
  @include scheme-pattern($scheme-name, $scheme) {
    
    // basic color settings for fields with no modifiers/variants
    input,
    textarea,
    select {
      border-color: color(utility-line, $scheme);
      color: color(input-text, $scheme);
    }

    // asterisk for required fields
    label[class*="--required"],
    fieldset[class*="--required"] legend {
      &:after {
        color: color(error-text, $scheme);
      }
    }

    // disabled fields
    input,
    select,
    textarea,
    fieldset,
    fieldset.radio,
    fieldset.checkboxes,
    label {
      &[disabled],
      &[class*="--disabled"] {
        color: color(input-disabled-text, $scheme);
        label,
        legend {
          color: color(input-disabled-text, $scheme);
        }
      }
    }
    fieldset,
    fieldset.radio,
    fieldset.checkboxes {
      &[disabled] {
        border-color: color(utility-line, $scheme);
      }
    }
    input,
    select,
    textarea {
      &[disabled] {
        background-color: color(input-disabled-background, $scheme);

        &::-webkit-input-placeholder {
          color: color(input-disabled-placeholder, $scheme);
        }
      }
    }

    // error & valid states
    input,
    select,
    textarea,
    fieldset,
    fieldset.radio,
    fieldset.checkboxes {
      &[class*="--error"] {
        border-color: color(error, $scheme);
      }
    
      &[class*="--valid"] {
        border-color: color(valid, $scheme);
      }
    }
    label,
    legend {
      &[class*="--error"] {
        color: color(error-text, $scheme);
      }
    
      &[class*="--valid"] {
        color: color(valid-text, $scheme);
      }
    }
  }
}