.image-gallery {
  // -1 gridblock in all directions to make up for gutter
  // around each of the image grid items
  margin: rr-gridbase(-1.5);
}

.modal {
  .modal__wrapper {
    max-width: 80vw;
    max-height: 80vh;

    img {
      max-width: 80vw;
      max-height: 80vw;
    }
  }
}

.image-gallery__block {
  > a {
    display: block;
    overflow: hidden;
    background: none;

    &:hover,
    &:active,
    &:focus {
      figure {
        img {
          transform: scale(1.1);
        }

        &::before,
        &::after {
          opacity: 1;
        }
      }
    }

    figure {
      position: relative;
      overflow: hidden;
      margin-bottom: 0;

      img {
        transition: transform .6s ease-in-out;
      }

      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        transition: opacity .3s ease-in-out;
        font-family: 'iconfont';
        font-size: 2.2em;
        line-height: 1.2;
        content: $icon-popup;
        opacity: 0;
        z-index: 10;
      }

      &::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: opacity .3s ease-in-out;
        box-shadow: inset 0 0 0 .5rem;
        content: '';
        opacity: 0;
        z-index: 10;
      }
    }

    figcaption {
      display: none;
    }
  }
}

.image-gallery__slide__title {
  @include rr-font-compute(l);
  font-weight: 500;
}

.image-gallery__block-sizer,
.image-gallery__block {
  width: calc(33.3333% - #{rr-gridbase(3)});
  padding: rr-gridbase(1.5);
}

.image-gallery__block--width2 {
  width: 66.6666%;
}

button.mfp-close {
  right: 0;
  width: 1.4em !important; // sass-lint:disable-line no-important
  padding: 0;
  line-height: 1;
  text-align: center;
  opacity: 1;
}

.mfp-image-holder {
  .mfp-bottom-bar {
    position: relative;
    top: auto;
    margin-top: 0;
  }

  .mfp-figure {
    // padding: 3rem;
    // background-color: color(white);
  }

  .mfp-figure::after {
    display: none;
  }

  .mfp-title {
    padding-right: 0;
  }

  .mfp-counter {
    top: auto;
    bottom: 100%;
  }

  .mfp-img {
    padding: 0;
  }

  figure img {
    max-height: 70vh;
    width: auto;
  }

  figure figcaption {
    border: 0;
  }

  @include rr-break-directive(l) {

    .mfp-portrait figure {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      max-width: 70vw;

      img {
        flex: 1 1 50%;
        max-width: 30vw;
        margin-right: 2rem;
      }

      figcaption {
        flex: 1 1 50%;
        margin-right: 2rem;
      }

      .mfp-bottom-bar {
        margin-top: 56px;
      }
    }
  }
}

// ==============================================
// scheming
@each $scheme-name, $scheme in $schemes {
  @include scheme-pattern($scheme-name, $scheme) {
    .image-gallery {
      .image-gallery__block {
        // keep all the state colors the same,
        // since all the transitions are happening on visibility/opacity.
        // prevents a flash of the default link colors when hovering out.
        & > a {
          figure {
            &:before {
              color: color(button-alternate-hover-background, $scheme);
              background-color: rgba(color(button-alternate-hover-background, $scheme), 0.5);
            }
            &:after {
              color: color(button-alternate-hover-text, $scheme);
            }
          }
          &:link,
          &:visited,
          &:hover,
          &:active,
          &:focus {
            figure {
              &:before {
                color: color(button-alternate-hover-background, $scheme);
                background-color: rgba(color(button-alternate-hover-background, $scheme), 0.5);
              }
              &:after {
                color: color(button-alternate-hover-text, $scheme);
              }
            }
          }
        }
      }
    }
  }
}