.search {
  @include pattern-stack-margin;
  form {
    .search__inputs {
      display: flex;
      input[type="text"],
      input[type="search"],
      select {
        flex: 1 1 auto;
        @include rr-font-compute(s, m);
      }
    }
    .button,
    button {
      line-height: 0;
    }
  }

  &.search--minimal {
    input[type="text"],
    input[type="search"] {
      border: 0;
    }
    button {
      border: 0;
      .icon {
        .icon__glyph {
          margin-right: 0;
        }
        .icon__label {
          @include visually-hidden;
        }
      }
    }
  }
  &.search--compound {
    display: flex;
    flex-flow: column nowrap;
    form {
      flex: 1 1 auto;
      .search__inputs {
        flex-flow: column nowrap;
      }
    }
    .search__link {
      margin-top: component-spacing(xs);
    }
    @include break-max($break-small) {
      form {
        .search__inputs {
          input[type="text"],
          input[type="search"],
          select {
            margin-bottom: component-spacing(xs);
            margin-right: 0;
          }
        }
      }
    }
    @include rr-break-directive(m) {
      flex-flow: row wrap;
      form {
        flex-flow: row nowrap;
        flex: 1 1 auto;
        .search__inputs {
          flex-flow: row nowrap;
          input[type="text"],
          input[type="search"],
          select {
            margin-right: component-spacing(xs);
            &:last-child {
              margin-right: 0;
            }
          }
          input[type="text"],
          input[type="search"] {
            flex: 1 0 40%;
          }
        }
      }
      .search__link {
        flex: 1 1 auto;
        margin-left: component-spacing(xs);
        display: flex;
        align-items: center;
      }
    }
    @include rr-break-directive(l) {
      flex-flow: row nowrap;
      form {
        .search__inputs {
          input[type="text"],
          input[type="search"] {
            flex: 1 0 60%;
          }
        }
      }
      .search__link {
        flex: 0 1 auto;
        margin-top: 0;
      }
    }
  }
}

// ==============================================
// scheming
@each $scheme-name, $scheme in $schemes {
  @include scheme-pattern($scheme-name, $scheme) {
    .search {
      &.search--minimal {
        input[type="text"],
        input[type="search"] {
          background-color: color(shade-background, $scheme);
          &::-webkit-input-placeholder {
            color: color(search-minimal-placeholder, $scheme);
          }
        }
        .button,
        button {
          background-color: color(shade-background, $scheme);
          color: color(link-text, $scheme);
          &:link,
          &:visited {
            color: color(link-text, $scheme);
          }
          &:hover,
          &:active,
          &:focus {
            background-color: color(button-hover-background, $scheme);
            color: color(button-hover-text, $scheme);
          }
        }
      }
      &.search--compound {
        a {
          color: color(link-alternate-text, $scheme);
          &:link,
          &:visited {
            color: color(link-alternate-text, $scheme);
          }
          &:hover,
          &:active,
          &:focus {
            color: color(link-alternate-hover-text, $scheme);
          }
        }
        .button,
        button {
          color: color(button-alternate-text, $scheme);
          background-color: color(button-alternate-background, $scheme);
          border-color: color(button-alternate-border, $scheme);
          &:link,
          &:visited {
            color: color(button-alternate-text, $scheme);
          }
          &:hover,
          &:active,
          &:focus {
            color: color(button-alternate-hover-text, $scheme);
            background-color: color(button-alternate-hover-background, $scheme);
            border-color: color(button-alternate-hover-border, $scheme);
          }
        }
      }
    }
  }
}