.collection {
  @include pattern-stack-margin;


  &.collection--hidden-intro {
    .section-intro {
      @include visually-hidden;
    }
  }

  // center the link list that follows the collection.
  // this works for inline link lists but we may need more controls
  // for complex ones as common combinations reveal themselves.
  &.collection--center-link-list {
    & > .grid + .link-list {
      text-align: center;
    }
  }

  // in a feature-first collection, the first item is 100% wide.
  // if it's a teaser, force a large headline
  &.collection--feature-first {
    & > .grid {
      & > :first-child {
        grid-column: 1 / -1;
        flex: 0 1 100%;
        &.teaser {
          .teaser__content {
            h1, h2, h3, h4, h5, h6,
            .headline-group,
            .headline-group .headline-group__head {
              @include rr-heading(h2, $heading-styles);
            }
          }
        }
      }
    }
  }

  // stacked teaser collections
  .grid.grid--xlarge {
    & > .teaser {
      &:last-child {
        margin-bottom: component-spacing(m);
      }
    }
  }
  @include rr-break-directive(l) {
    .grid.grid--xlarge {
      & > .teaser {
        display: flex;
        flex-flow: row nowrap;
        .box__content {
          flex: 1 1 auto;
          order: 1;
        }
        .box__footer {
          flex: 1 0 35%;
          order: 2;
          margin-left: $grid-gap;
        }
      }
    }
  }
}

// ==============================================
// scheming
// icon colorizing can happen two ways: by the parent slab, or by the box
@each $scheme-name, $scheme in $schemes {
  @include scheme-pattern($scheme-name, $scheme) {
    .collection {
      // auto-colorize icon by parent slab
      &.collection--colorize-icons {
        .grid {
          & > :nth-child(4n+1) {
            .icon--circle .icon__glyph::after {
              background-color: color(collection-icon-first, $scheme);
            }
          }
          & > :nth-child(4n+2) {
            .icon--circle .icon__glyph::after {
              background-color: color(collection-icon-second, $scheme);
            }
          }
          & > :nth-child(4n+3) {
            .icon--circle .icon__glyph::after {
              background-color: color(collection-icon-third, $scheme);
            }
          }
          & > :nth-child(4n+4) {
            .icon--circle .icon__glyph::after {
              background-color: color(collection-icon-fourth, $scheme);
            }
          }
        }
      }
    }
  }
}
// auto-colorize icon by boxes in grid
.collection {
  &.collection--colorize-icons {
    .grid {
      @each $scheme-name, $scheme in $schemes {
        @include scheme-pattern($scheme-name, $scheme, 'box') {
          &:nth-child(4n+1) {
            .icon--circle .icon__glyph::after {
              background-color: color(collection-icon-first, $scheme);
            }
          }
          &:nth-child(4n+2) {
            .icon--circle .icon__glyph::after {
              background-color: color(collection-icon-second, $scheme);
            }
          }
          &:nth-child(4n+3) {
            .icon--circle .icon__glyph::after {
              background-color: color(collection-icon-third, $scheme);
            }
          }
          &:nth-child(4n+4) {
            .icon--circle .icon__glyph::after {
              background-color: color(collection-icon-fourth, $scheme);
            }
          }
        }
      }
    }
  }
}