/* This file has been generated from a template. Do not edit it directly, your edits will 
   be ignored like a ten-year-old's dad. Instead, edit the source template in 
   /assets/font-svg/_iconfont-vars.scss. 

   This is a lodash template.
*/

%iconfont {
    font-family: "icons-862743408";
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


$icon-angle-left: "\EA01";
    
$icon-angle-right: "\EA02";
    
$icon-arrow-circle-right: "\EA03";
    
$icon-arrow-right: "\EA04";
    
$icon-audio: "\EA05";
    
$icon-bolt: "\EA06";
    
$icon-camera: "\EA07";
    
$icon-check: "\EA08";
    
$icon-clock: "\EA09";
    
$icon-close: "\EA0A";
    
$icon-cog-box: "\EA0B";
    
$icon-envelope: "\EA0C";
    
$icon-event: "\EA0D";
    
$icon-exclamation-circle: "\EA0E";
    
$icon-exclamation-triangle: "\EA0F";
    
$icon-exclamation: "\EA10";
    
$icon-external: "\EA11";
    
$icon-facebook-block: "\EA12";
    
$icon-facebook: "\EA13";
    
$icon-hamburger: "\EA14";
    
$icon-home: "\EA15";
    
$icon-info: "\EA16";
    
$icon-instagram-block: "\EA17";
    
$icon-instagram: "\EA18";
    
$icon-iv-bag: "\EA19";
    
$icon-linkedin-block: "\EA1A";
    
$icon-linkedin: "\EA1B";
    
$icon-magnify: "\EA1C";
    
$icon-map-pin-line: "\EA1D";
    
$icon-news: "\EA1E";
    
$icon-pause: "\EA1F";
    
$icon-pdf: "\EA20";
    
$icon-phone: "\EA21";
    
$icon-pin: "\EA22";
    
$icon-pinterest-block: "\EA23";
    
$icon-pinterest: "\EA24";
    
$icon-play-triangle: "\EA25";
    
$icon-plus-circle: "\EA26";
    
$icon-popup: "\EA27";
    
$icon-quote-close: "\EA28";
    
$icon-quote-open: "\EA29";
    
$icon-rss: "\EA2A";
    
$icon-snapchat-block: "\EA2B";
    
$icon-snapchat: "\EA2C";
    
$icon-star-empty: "\EA2D";
    
$icon-star-filled: "\EA2E";
    
$icon-star-half: "\EA2F";
    
$icon-stethoscope: "\EA30";
    
$icon-twitter-block: "\EA31";
    
$icon-twitter: "\EA32";
    
$icon-video: "\EA33";
    
$icon-youtube-block: "\EA34";
    
$icon-youtube: "\EA35";
    