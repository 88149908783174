// removes scroll when modal is opened 

.no-scroll {
  overflow: hidden;
}

// overlay covers everything 

.modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: .89;
  z-index: 666;
}


.modal-display {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: $global-width;
  margin: 0;
  border-width: 0;
  z-index: 667;
  overflow-x: none;
  overflow-y: auto;
  @include rr-break-directive(l) {
    position: fixed;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    max-height: 95vh;
    padding: rr-gridbase(4);
    transform: translateX(-50%) translateY(-50%);
  }
}

.modal-display,
.mfp-figure {
  background: white;
  padding: rem(48px);
}

.modal-close {
  position: absolute;
  top: rr-gridbase(2);
  right: rr-gridbase(2);
  padding: 0;
  border: 0;
  opacity: 1;

  &::after {
    display: inline-block;
    margin-left: .3em;
    font-family: 'iconfont';
    font-size: 2em;
    content: $icon-close;
    vertical-align: middle;
  }
}
