.nc-placeholder {
  padding-top: rr-gridbase(4);
  padding-bottom: rr-gridbase(4);
  font-family: sans-serif;
  font-weight: bold;
  text-align: center;
  box-shadow: color(newcity-green) 0 0 0 6px inset;

  p {
    @include rr-font-compute(l, l);
  }
}
