.js-tabs {
  .js-tablist {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;

    .js-tablist__item {
      margin-bottom: 0;

      a {
        display: inline-block;
        cursor: pointer;

        &:focus {
          outline: 0;
        }
      }
    }
  }
  .js-tabcontent {
    padding: component-spacing(m);

    &[aria-hidden='true'] {
      display: none;
    }
  }
}

// ==============================================
// scheming
@each $scheme-name, $scheme in $schemes {
  @include scheme-pattern($scheme-name, $scheme) {
    .js-tabs {
      .js-tablist {
        .js-tablist__item {
          a {
            color: color(link-text, $scheme);
            background-color: color(background, $scheme);
            border-color: color(background, $scheme);
            &:hover,
            &:active,
            &:focus {
              color: color(link-hover-text, $scheme);
              background-color: color(background, $scheme);
              border-color: color(background, $scheme);
            }
            &[aria-selected='true'] {
              color: color(text, $scheme);
              background-color: color(background, $scheme);
              border-bottom-color: color(accent-line, $scheme);
              &:focus {
                color: color(link-hover-text, $scheme);
              }
            }
          }
        }
      }
      .js-tabcontent {
        background: color(shade-background, $scheme);
        // border-color: color(utility-line, $scheme);
      }
    }
  }
}