@media print {
  body {
    font-size: 14px;
    line-height: 1;
  }

  picture, img, iframe {
    max-width: 400px;
  }

  // better header
  header.header {
    padding: 0;
    margin: 0;
    * {
      display: none;
    }
    &:after {
      display: block;
      content: 'Emory Winship Cancer Institute';
      @include rr-font-compute(xl, m);
    }
  }

  // hide nav items and other unnecessary things
  footer.footer,
  header.header button.toggle-button,
  button.section-nav__toggle-button,
  figure .nice-video a.nice-video--control {
    display: none;
  }

  // remove slab padding and width constraints, let page width decide this
  .slab {
    padding-left: 0;
    padding-right: 0;
    .slab__wrapper {
      max-width: none;
      width: auto;
    }
  }

  // tighten grid-gap, waste less paper on whitespace hopefully.
  // using important to apply this to all grids regardless of scale variant
  .grid {
    > * {
      margin: 0;
    }
    @supports(display: grid) {
      grid-gap: 10px !important;
    }
  }

  // remove schemes
  // using important to prevent needing to do the schemes loop,
  // and generate a bunch of css unnecessarily 
  [class*='scheme--'] {
    background: #fff !important;
    color: #000 !important;
    padding: 0 !important;

  }

}