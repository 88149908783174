.float--left,
.float--right {
    @include rr-break-directive(m) {
        max-width: float-width($float-default-width, $float-gutter, false, false);
        &.float--third {
            max-width: float-width($float-thirds-width, $float-gutter, false, false);
        }
    }
}

.float--left {
    @include rr-break-directive(m) {
        float: left;
        margin-right: $float-gutter;
    }
}

.float--right {
    @include rr-break-directive(m) {
        float: right;
        margin-left: $float-gutter;
    }
}

// allow floats to break out of the "container" on centered narrow slabs
.slab--narrow-center.slab--allow-float-breakout {
    .float--left,
    .float--right {
        @include rr-break-directive(l) {
            max-width: float-width($float-default-width/2, $float-gutter, $global-width, $grid-uneven-major-size);
            &.float--third {
                max-width: float-width($float-thirds-width/2, $float-gutter, $global-width, $grid-uneven-major-size);
            }
        }
    }
    .float--left {
        @include rr-break-directive(xl) {
            margin-left: float-breakout-margin($float-gutter, $global-width, $grid-uneven-major-size);
        }
        @include rr-break-directive(l) {
            margin-left: float-breakout-margin($float-gutter, false, $grid-uneven-major-size);
        }
    }
    .float--right {
        @include rr-break-directive(xl) {
            margin-right: float-breakout-margin($float-gutter, $global-width, $grid-uneven-major-size);
        }
        @include rr-break-directive(l) {
            margin-right: float-breakout-margin($float-gutter, false, $grid-uneven-major-size);
        }
    }
}