.list-control {
  @include rr-break-directive(m) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .list-control__control {
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: component-spacing(s);
    @include text-small-bold;
    @include break-min($break-2xsmall) {
      flex-flow: row nowrap;
      align-items: center;
      .list-control__prefix {
        margin-right: component-spacing(2xs);
      }
      .list-control__suffix {
        margin-left: component-spacing(2xs);
      }
    }
    .list-control__widget {
      select {
        // smaller selects in list control
        @include rr-font-compute(xs, m);
        background-size: 0.8em;
        padding-top: component-spacing(3xs);
        padding-bottom: component-spacing(3xs);
        padding-left: component-spacing(2xs);
        // not right side bc arrow visibility
      }
    }
  }
}

// ==============================================
// list control inside a sidebar layout
.grid.sidebar .grid__sidebar {
  .list-control {
    flex-flow: column nowrap;
    align-items: flex-start;
  }
}