.skip-nav {
  @include rr-font-compute(xl, l);
  padding: component-spacing(2xs);
  display: flex;
  position: absolute;
  z-index: 9999;
  top: -5rem;
  justify-content: center;
  width: 100%;

  background-color: color(light-blue);
  color: color(white);
  &:link,
  &:visited {
    color: color(white);
  }
  &:active,
  &:hover,
  &:focus {
    color: color(white);
  }

  &:focus {
    top: 0;
    transition: top .5s;
  }
}

// use these link colors no matter what the scheme is
@each $scheme-name, $scheme in $schemes {
  @include scheme-pattern($scheme-name, $scheme, null, false, false) {
    .skip-nav {
      color: color(white);
      &:link,
      &:visited {
        color: color(white);
      }
      &:active,
      &:hover,
      &:focus {
        color: color(white);
      }
    }
  }
}