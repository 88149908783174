.icon__glyph {
  display: inline-block;
  width: 1em;
  height: 1em;
}

.icon {
  display: inline-flex;
  align-items: center;

  .icon__glyph {
    width: auto;
    margin-right: $icon-glyph-label-spacing;
    vertical-align: baseline;
  }

  .icon__label {
    white-space: normal;
  }
}

.icon--stacked {
  flex-direction: column;
  align-items: center;
  line-height: 1.1;

  .icon__glyph {
    margin-right: 0;
    font-size: 2.5em;
  }
}

.icon--big {
  @include rr-font-compute(xl);
  align-items: center;

  .icon__glyph {
    position: relative;
    align-self: flex-start;
    margin-right: .5em;
    font-size: 2em;
  }

  @at-root button #{&},
  .button #{&} {
    .icon__label {
      font-size: 1em;
      line-height: 1.1;
      text-align: left;
    }
  }
}

.icon--hide-label {
  .icon__label {
    @include visually-hidden;
  }
}

.icon.icon--circle {
  .icon__glyph {
    font-size: 1.55em;
    position: relative;
    margin-right: 1em;
    height: $icon-circle-size;
    // this color might be declared somewhere else once we systematize how the circles are colorized
    color: #ffffff;

    // the circle
    &:after {
      content: '';
      display: inline-block;
      border-radius: 50%;
      width: $icon-circle-size;
      height: $icon-circle-size;
    }
    // the icon glyph
    &:before {
      position: absolute;
      left: 0;
      right: 0;
      z-index: 2;
      line-height: $icon-circle-size;
      text-align: center;
    }
  }

  &.icon--hide-label {
    .icon__glyph {
      margin-right: 0;
    }
  }

  &.icon--stacked {
    .icon__glyph {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 1em;
    }
  }
}

// ==============================================
// scheming
@each $scheme-name, $scheme in $schemes {
  @include scheme-pattern($scheme-name, $scheme) {
    .icon.icon--circle .icon__glyph:after {
      // this is probably going to change.
      // icon-circles can have lots of colors within a single scheme.
      // need to discuss w/design how we'll systematize.
      background-color: color(accent-line, $scheme);
    }
  }
}
