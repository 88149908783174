.search-result {
  p {
    margin-top: 0;
    margin-bottom: component-spacing(2xs);
    &:last-child {
      margin-bottom: 0;
    }
  }
  p.search-result__summary {
    @include rr-font-compute(xs, m);
  }
  p.search-result__path {
    @include rr-font-compute(xs, m);
    font-style: italic;
    word-break: break-word;
  }
  ul.list--inline-terms {
    &:not(:last-child) {
      margin-bottom: component-spacing(2xs);
    }
  }
  .term {
    @include rr-font-compute(xs, m);
  }
  .meta {
    @include rr-font-compute(xs, m);
    .link-list {
      &:not(:first-child) {
        ul {
          margin-top: component-spacing(2xs);
        }
      }
      ul {
        li {
          @include rr-font-compute(xs, m);
        }
      }
    }
  }
}

// ==============================================
// scheming
@each $scheme-name, $scheme in $schemes {
  @include scheme-pattern($scheme-name, $scheme) {
    .search-result {
      p.search-result__path {
        color: color(utility-text, $scheme);
      }
    }
  }
}