blockquote {
  margin: 0 0 component-spacing(m) component-spacing(s);
  padding-left: component-spacing(s);
  border-left-style: solid;
  border-left-width: $accent-line-thickness;
  p {
    &:first-child {
       margin-top: 0;
    }
  }
}


@each $scheme-name, $scheme in $schemes {
  @include scheme-pattern($scheme-name, $scheme) {
    blockquote {
      border-left-color: color(utility-line, $scheme);
    }
  }
}

@each $scheme-name, $scheme in $schemes {
  @include scheme-pattern($scheme-name, $scheme, blockquote, true) {

  }
}