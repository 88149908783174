.global-nav,
.utility-nav {
  .link-list {
    ul {
      @include clean-list;
        @include rr-break-directive(l) {
        display: flex;
      }
    }
  }
}