button.section-nav__toggle-button {
  width: 100%;
  margin-bottom: component-spacing(s);
  @include rr-break-directive(l) {
    display: none;
  }
}
.section-nav {
  border-style: solid;
  border-width: $accent-line-thickness;
  // border color comes from scheme
  padding: component-spacing(s);
  margin-bottom: component-spacing(m);
  overflow: hidden;
  a {
    text-decoration: none;
    font-weight: normal;
    transition: all $transition-time $transition-easing;
  }

  h2#section-nav {
    margin: 0;
  }
  .section-intro {
    margin-bottom: component-spacing(xs);
    .headline-group {
      @include text-small-bold;
      .headline-group__head {
        @include text-small-bold;
      }
    }
  }
  .link-list {
    ul {
      list-style: none;
      border-left-style: solid;
      border-left-width: $accent-line-thickness;
      padding-left: component-spacing(s);
      margin-bottom: 0;
      li {
        position: relative;
        padding-right: component-spacing(m);
        & > * {
          position: relative;
          z-index: 2;
        }
        & > a {
          @include rr-font-compute(s, l);
        }
        .toggle-button {
          z-index: 2;
          position: absolute;
          top: -(component-spacing(2xs));
          right: -(component-spacing(s));
          padding-bottom: (component-spacing(2xs)/2);
          .icon {
            .icon__glyph {
              margin: 0;
              transform: rotate(-90deg);
              transition: transform $transition-time $transition-easing;
            }
          }
        }
        &:after {
          content: '';
          display: block;
          position: absolute;
          z-index: 1;
          top: -(component-spacing(2xs));
          bottom: -(component-spacing(2xs));
          right: rem(-9999px);
          left: rem(-9999px);
          mix-blend-mode: multiply;
          transition: background-color $transition-time $transition-easing;
        }
        ul {
          flex: 1 1 100%;
          border: 0;
          padding-left: 0;
          // nested lists hidden at start (unless this is the active section)
          display: none;
          li {
            padding-right: 0;
            &:last-child {
              padding-bottom: component-spacing(2xs);
            }
            a {
              font-weight: normal;
              @include rr-font-compute(xs, l);
            }
          }
        }
        &.section-nav--active-section,
        &.section-nav--toggle-parent-active {
          & > a {
            position: relative;
            font-weight: bold;
            &:before {
              content: '';
              display: inline-block;
              position: absolute;
              top: -(component-spacing(2xs));
              bottom: -(component-spacing(2xs));
              width: $accent-line-thickness;
              left: calc(#{-(component-spacing(s))} + #{-($accent-line-thickness)});
            }
          }
        }
        &.section-nav--active-section {
          ul {
            display: block;
          }
        }
        &.section-nav--toggle-parent-active {
          .toggle-button {
            .icon {
              .icon__glyph {
                transform: rotate(90deg);
              }
            }
          }
        }
      }
    }
  }
}

// allow filter forms inside the section nav
// error states are disabled since this is a filter form and not a submit-type form,
// no filter can be "required" to submit.
.section-nav {
  .link-list {
    .toggle__label {
      font-weight: bold;
    }
    fieldset {
      padding: 0;
      &.checkboxes,
      &.radio {
        margin: 0;
        margin-bottom: -(component-spacing(xs));
      }
      legend {
        padding: 0;
        margin-bottom: component-spacing(xs);
      }
      label {
        @include rr-font-compute(xs, m);
      }
      ul {
        li {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    ul {
      li {
        // don't auto-hide the uls that wrap checkboxes and radio groups
        fieldset {
          ul {
            display: block;
            li {
              padding-bottom: 0;
            }
          }
        }
        ul {
          li {
            fieldset {
              // nested lists use the toggle label as the visual label
              // keep the real one for accessibility
              legend {
                @include visually-hidden;
              }
            }
          }
        }
      }
    }
  }
}
// in only the filter case, tweak some spacing on the li's with form fields
.section-nav.section-nav--filters {
  .link-list {
    & > ul {
      & > li {
        
        // margin-bottom: 0;
      }
    }
  }
}

// ==============================================
// scheming
@each $scheme-name, $scheme in $schemes {
  @include scheme-pattern($scheme-name, $scheme) {
    .section-nav {
      border-color: color(shade-background, $scheme);
      a {
        @include faux-underline($underline-color: transparent, $indicate-action: true);
      }
      .button.toggle-button,
      button.toggle-button {
        background-color: transparent;
        border-color: transparent;
        color: color(accent-line, $scheme);
        &:hover,
        &:active,
        &:focus {
          color: color(link-hover-text, $scheme);
        }
      }
      li.section-nav--active-section,
      li.section-nav--toggle-parent-active {
        &::after {
          background-color: color(shade-background, $scheme);
        }
        & > a {
          color: color(text, $scheme);
          &:link,
          &:visited {
            color: color(text, $scheme);
          }
          &:before {
            background-color: color(accent-line, $scheme);
          }
        }
      }
      ul {
        border-color: color(utility-line, $scheme);
      }
    }
  }
}

// ==============================================
// mobile behavior
// on small screens, keep sidebar content below main content,
// but show section nav before main content
.section-nav.section-nav--mobile,
.section-nav:not(.section-nav--filters) button.section-nav__toggle-button {
  @include rr-break-directive(l) {
    // force the mobile nav to hide on large screens,
    // even if the user has toggled it to "on" then 
    // scaled up the window manually
    display: none !important;
  }
}
.section-nav {
  display: none;
  &:not(.section-nav--mobile) {
    @include rr-break-directive(l) {
      // force the nav to always show on large screens,
      // even if the user is scaling the screen up and down
      display: block !important;
    }
  }
}
.grid.sidebar {
  .grid__sidebar {
    button.section-nav__toggle-button:not(.section-nav__toggle-filters) {
      display: none;
    }
  }
}