.timeline-box {
  &.grid {
    .box__intro {
      grid-column: 1 / -1;
      flex: 0 1 100%;
      .section-intro {
        margin-bottom: 0;
      }
    }
  }
}