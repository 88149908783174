.breadcrumbs {
  display: none;
  padding: component-spacing(2xs) component-spacing(xs);
  @include rr-break-directive(m) {
    display: block;
  }
  ul {
    @include clean-list;
    li {
      display: inline;
      @include rr-font-compute(2xs, s);
      font-weight: bold;
      @include slash-after;
    }
  }
}

// tbd should breadcrumbs be part of scheme
.breadcrumbs {
  background: color(light-blue);
  color: color(white);
  li {
    &:after {
      color: color(sky-blue);
    }
    a {
      color: color(white);
      &:link,
      &:visited {
        color: color(white);
      }
      &:hover,
      &:active {
        color: color(light-blue);
      }
    }
  }
}