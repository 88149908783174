$color-undefined: #EA0FC0;

/// Returns the luminance of `$color` as a float (between 0 and 1)
/// 1 is pure white, 0 is pure black
/// @param {Color} $color - Color
/// @return {Number}
/// @link http://www.w3.org/TR/2008/REC-WCAG20-20081211/#relativeluminancedef Reference
@function luminance($color) {
  $colors: ('red': red($color), 'green': green($color), 'blue': blue($color));

  @each $name, $value in $colors {
    $adjusted: 0;
    $value: $value / 255;

    @if $value < .03928 {
      $value: $value / 12.92;
    } @else {
      $value: ($value + .055) / 1.055;
      $value: pow($value, 2.4);
    }
    $colors: map-merge($colors, ($name: $value));
  }
  @return (map-get($colors, 'red') * .2126) + (map-get($colors, 'green') * .7152) + (map-get($colors, 'blue') * .0722);
}


// check the contrast ratio between two colors
@function contrast($c1, $c2) {
  $l1: luminance($c1);
  $l2: luminance($c2);
  @if $l1 > $l2 {
    @return ($l1 + .05) / ($l2 + .05);
  }
  @return ($l2 + .05) / ($l1 + .05);
}


// return a color name from the map
@function color($name: primary, $scheme: $colors) {
  @if map-has-key($scheme, $name) {
    @return map-get($scheme, $name);
  }
  @return $color-undefined;
}

// return a non-color value from the scheme map
@function scheme-value($value: 0, $scheme: $colors) {
  @if map-has-key($scheme, $value) {
    @return map-get($scheme, $value);
  }
  @return 0;
}

// show-color is used by color swatches in the pattern demos/style guide
@mixin show-color($color, $annotate: false) {
  $white: #fff;
  $black: #000;
  $contrast: contrast($color, $black);

  background-color: $color;

  @if ($contrast > 4.5) {
    color: $black;
  } @else {
    color: $white;
    $contrast: contrast($color, $white);
  }

  @if ($annotate) {
    &::before {
      display: block;
      font-weight: normal;
      content: '#{$color}';
    }
    &::after {
      display: block;
      font-weight: normal;
      content: '#{round($contrast * 10) / 10}';
    }
  }
}





// force a pattern to use a specific color scheme
// tbd we might not need this anymore
@mixin usescheme($colorscheme) {
  background-color: color(background, $colorscheme);
  color: color(text, $colorscheme);
} 

// scheme a pattern from its own scss file
@mixin scheme-pattern($scheme-name, $scheme, $pattern: null, $element: false, $nesting: true) {
  $base-selector: "";
  // if pattern is null it has no specific scheme-able parts itself,
  // and we're just defining how it behaves as a child
  // ex.: .scheme--example a
  @if $pattern == null {
    $base-selector: ".scheme--#{$scheme-name}";
  } @else {
    // if a pattern is an html element instead of a class
    // ex.: blockquote.scheme--example
    @if $element {
      $base-selector: "#{$pattern}.scheme--#{$scheme-name}";
    } @else {
      // otherwise the pattern is a class being schemed itself
      // ex.: .box.scheme--example
      $base-selector: ".scheme--#{$scheme-name}.#{$pattern}";
    }
  }

  // allow scheme nesting
  $selector: '';
  @if $nesting {
    $selector: scheme-selector-multiplier($base-selector);
  } @else {
    $selector: $base-selector;
  }

  #{$selector} {
    @content;
  }
}

// automate building of scheme selectors up to n levels deep
@function scheme-selector-multiplier($base-selector, $levels: 5) {
  $selector: $base-selector + ", ";
  $multiplier: "";

  @for $i from 1 through $levels {
    $multiplier: "";
    @for $j from 1 through $i {
      $multiplier: "[class*=scheme--] " + $multiplier;
    }
    $selector: $selector + $multiplier + $base-selector;
    @if $i < $levels {
      $selector: $selector + ",";
    }
  }

  @return $selector;
}