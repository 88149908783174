.video {
    &.video--responsive {
        position: relative;
        &:after {
            content: '';
            display: block;
            padding-bottom: 56.25%;
        }
        iframe {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            margin: 0;
        }
    }
}