// ==============================================
// general and init vars
$global-width: rem(1300px);

// breakpoints
$break-2xsmall: rem(300px);
$break-xsmall: rem(400px);
$break-small: rem(700px);
$break-medium: rem(1024px);
$break-large: $global-width;
$break-xlarge: $global-width + 4rem;

// special breakpoints
$break-square: $break-medium; // when to un-square square boxes
$break-page-header: rem(850px);
$break-sidebar: $break-medium;

// transitions
$transition-time: 0.25s;
$transition-easing: ease-in-out;

// misc
$image-url-path: '../images/';


// ==============================================
// root rhythm & baseline grid settings
$rr-blend-mode: multiply;
$rr-grid-divisions: 2;
$rr-maxwidth: $global-width;
$rr-grid-settings: (
  s: (
    mq: max-width,
    size: $break-small,
    columns: 4,
    gutter: 1rem / $rr-grid-divisions,
    offset: 1rem,
    vspace: 2,
    column_color: rgba(200, 200, 255, .2),
    baseline_color: rgba(128, 128, 200, .2)
  ),
  m: (
    mq: min-width,
    size: $break-small,
    gutter: 1rem,
    columns: 8,
    offset: 2rem,
    vspace: 3,
    column_color: rgba(255, 200, 200, .2),
    baseline_color: rgba(128, 128, 200, .2)
  ),
  l: (
    size: $break-medium,
    columns: 12,
    vspace: 4,
    column_color: rgba(128, 255, 200, .2),
    baseline_color: rgba(128, 128, 200, .2)
  ),
  xl: (
    size: $break-xlarge,
    offset: 0,
    gutter: 2rem,
    vspace: 5,
    column_color: rgba(200, 128, 255, .2),
    baseline_color: rgba(128, 128, 200, .2)
  ),
  xlp: (
    size: $global-width + 8rem
  )
);


// ==============================================
// typography & fonts
$font-primary: 'Noto Sans', sans-serif;
$font-secondary: 'Spectral', serif;
$font-tertiary: 'Barlow Condensed', sans-serif;

$lineheights: (
  2xs: -1,
  xs: 0,
  s: 0.5,
  m: 1,
  l: 1.4,
  xl: 2,
  2xl: 3
);

// font sizes are grouped by media query
// allowing for different font scaling at different screen sizes
$fonts: (
  // sizes for small screens
  s: (
    2xs: rem(12px),
    xs: rem(14px),
    s: rem(16px),
    m: rem(19px),
    l: rem(20px),
    xl: rem(22px),
    2xl: rem(24px),
    3xl: rem(26px),
    4xl: rem(26px),
    5xl: rem(32px),
    6xl: rem(48px)
  ),
  // sizes for larger screens
  m: (
    2xs: rem(12px),
    xs: rem(14px),
    s: rem(16px),
    m: rem(19px),
    l: rem(20px),
    xl: rem(22px),
    2xl: rem(24px),
    3xl: rem(26px),
    4xl: rem(32px),
    5xl: rem(48px),
    6xl: rem(56px)
  )
);
$font-sizes: rr-font-sizes();
$rr-fonts: $fonts;
$rr-lineheights: $lineheights;

$heading-styles: (
  h1: (
    size: 6xl,
    lineheight: m,
    font: $font-secondary,
    weight: normal,
  ),
  h2: (
    size: 5xl,
    lineheight: m,
    font: $font-secondary,
    weight: normal,
  ),
  h3: (
    size: 4xl,
    lineheight: m,
    font: $font-secondary,
    weight: normal,
  ),
  h4: (
    size: 3xl,
    lineheight: m,
    font: $font-tertiary,
    weight: normal,
    style: normal
  ),
  h5: (
    size: 2xl,
    lineheight: m,
    font: $font-tertiary,
    weight: normal,
    style: normal
  ),
  h6: (
    size: xl,
    lineheight: m,
    font: $font-tertiary,
    weight: normal
  )
);

// ==============================================
// spacing & measures for components

// component-spacing is used to set general
// spacing standards in and around components.
$component-spacing: (
  flat: 0,
  3xs: rr-gridbase(0.5),
  2xs: rr-gridbase(1),
  xs: rr-gridbase(2),
  s: rr-gridbase(3),
  m: rr-gridbase(4),
  l: rr-gridbase(6),
  xl: rr-gridbase(8),
  2xl: rr-gridbase(10)
);

// ==============================================
// sizes, dimensions, spacing, and measures

// accent line is a common visual divider and design element
$accent-line-thickness: rem(2px);

// utility line is a utilitarian separator/divider
$utility-line-thickness: rem(1px);

// floating line is specifically the line below fancy headings
$floating-line-thickness: $accent-line-thickness;
$floating-line-width: rem(40px);

// forms
$form-field-border-size: $accent-line-thickness;
$form-field-spacing: component-spacing(xs);

// lists
$list-loose: component-spacing(s);
$list-regular: component-spacing(xs);
$list-tight: component-spacing(flat);

// buttons
// all buttons have the border
$button-padding-top-bottom: rem(12px);
$button-padding-left-right: rem(17px);

// floats
$float-gutter: component-spacing(m);
$float-default-width: 50%;
$float-thirds-width: 33.333%;

// icons & glyphs
$social-media-circle-size: rem(28px);
$icon-glyph-label-spacing: .55em;
$icon-circle-size: rem(50px);

// misc
$page-header-padding: component-spacing(l);
$sidebar-min-width: rem(300px);
$fancy-collection-overlap: 40%;

// ==============================================
// css grid layout settings
// not to be confused with the rr/baseline grid -
// these styles are used in the molecules > layout > .grid pattern
// for defaults when using 'display: grid' (and relevant fallbacks)

// settings for all grids
$grid-gap: component-spacing(xl);
$grid-gap-narrow: component-spacing(s);
$grid-gap-fancy-collection: component-spacing(2xs);

// fixed grids (default) maintain a specific number of cells per row,
// and scale the cells to fit a two/three/etc column layout.
// "$grid-columns-medium: 3" makes a three-column grid on large screens.
$grid-columns-xsmall: 5;
$grid-columns-small: 4;
$grid-columns-medium: 3;
$grid-columns-large: 2;
$grid-columns-xlarge: 1;

// flexible grids maintain the grid cells at a minimum width,
// then fit as many cells as can fit in each row.
// "min" here refers to the minimum size cells should be
// for that particular grid variant (medium is the default/unmodified).
$grid-flexible-small-min: 10rem;
$grid-flexible-medium-min: 20rem;
$grid-flexible-large-min: $global-width/2;

// uneven grids
// for grids with uneven column splits (like a sidebar layout)
// $grid-uneven-major-size is the width larger/major cell.
// this can be a explicit width like rem(800px) OR a percentage.
// if you set an explicit width, the major cell will maintain that width,
// provided there is screen size to do so.
// 
// note that if you set a "major" size that is less than half the available width,
// the cell will appear to be the minor instead of the major.
// for example, if major is fixed at 800px but the container width is <1600px
$grid-uneven-major-size: 66.66666%;
$sidebar-major-size: rem(844px);
$page-header-uneven-major-size: 55%;
$page-header-offset-major-size: $grid-uneven-major-size;

// ==============================================
// colors & schemes

$colors: (
  emory-blue: #012169,
  dark-blue: #0c2340,
  medium-blue: #0033a0,
  light-blue: #007dba,
  yellow: #f2a900,
  gold: #b58500,
  metallic-gold: #84754e,

  cyan: #00aeef,
  sky-blue: #41b6e6,
  teal: #487f84,
  kelly-green: #348338,
  sea-green: #006c5b,
  olive: #5c8118,
  orange: #c35413,
  red: #da291c,
  magenta: #c6007e,
  purple: #6558b1,
  grape: #6d2077,

  white: #ffffff,
  lightest-yellow: #fae4b0,
  lightest-blue: #ebf5f9,
  lightest-gray: #EBEBE9,
  // lighter-gray: #undefined_delete_later,
  light-gray: #D0D0CE,
  medium-gray: #B1B3B3,
  dark-gray: #707171,
  darker-gray: #252525,
  darkest-gray: #222222,
  black: #000000,
);

// non-palette helper colors
// mostly to ensure ok contrast on error/valid states
$color-error-text-dark: darken(color(red), 10%);
$color-error-text-light: color(yellow);
$color-valid-text-dark: darken(color(kelly-green), 10%);
$color-valid-text-light: lighten(color(kelly-green), 20%);
$color-disabled-text-dark: darken(color(dark-gray), 4%);
// slightly darker light-blue to pass contrast on light-but-not-white schemed bg's
$color-light-blue-contrast: darken(color(light-blue), 5%);
// faux-underline requires a full-opacity value to generate the gradient,
// so this is fake 25% alpha white on emory-blue and yellow
// (which breaks if the scheme bg changes!)
$color-faux-alpha-white-emory-blue: #516492;
$color-faux-alpha-white-yellow: #FFC950;

$schemes: (
  white: (
    background: color(white),
    text: color(darker-gray),
    accent-text: color(light-blue),
    utility-text: color(dark-gray),
    shade-background: rgba(color(lightest-gray), 0.7),
    shade-background-alternate: color(lightest-blue),
    
    utility-line: color(light-gray),
    accent-line: color(red),
    accent-line-secondary: color(yellow),
    accent-line-tertiary: color(sky-blue),

    error: color(red),
    warning: color(yellow),
    valid: color(kelly-green),
    error-text: color(red),
    valid-text: color(kelly-green),
    input-text: color(darker-gray),
    input-disabled-text: color(dark-gray),
    input-disabled-background: color(lightest-gray),
    input-disabled-placeholder: color(dark-gray),
    search-minimal-placeholder: color(dark-gray),

    link-text: color(medium-blue),
    link-underline: color(sky-blue),
    link-hover-text: color(light-blue),
    link-hover-underline: lighten(color(sky-blue), 37%),
    link-alternate-text: color(medium-blue),
    link-alternate-hover-text: color(light-blue),

    button-text: color(white),
    button-background: color(light-blue),
    button-border: color(light-blue),
    button-hover-text: color(white),
    button-hover-background: color(dark-blue),
    button-hover-border: color(dark-blue),
    button-alternate-text: color(white),
    button-alternate-background: color(medium-blue),
    button-alternate-border: color(medium-blue),
    button-alternate-hover-text: color(white),
    button-alternate-hover-background: color(dark-blue),
    button-alternate-hover-border: color(dark-blue),
    button-disabled-text: $color-disabled-text-dark,
    button-disabled-background: color(lightest-gray),
    button-disabled-border: color(lightest-gray),

    texture-grid-opacity: 0.1,
    texture-grid-invert: false,

    superhead: color(darker-gray),
    subhead: color(darker-gray),

    collection-icon-first: color(light-blue),
    collection-icon-second: color(sea-green),
    collection-icon-third: color(grape),
    collection-icon-fourth: color(orange),

    page-header-line: color(red)
  ),

  lightest-gray: (
    background: color(lightest-gray),
    text: color(darker-gray),
    accent-text: color(light-blue),
    utility-text: darken(color(dark-gray), 5%),
    shade-background: rgba(color(dark-gray), 0.1),
    shade-background-alternate: rgba(color(dark-gray), 0.1),

    utility-line: color(light-gray),
    accent-line: color(red),
    accent-line-secondary: color(yellow),
    accent-line-tertiary: color(sky-blue),

    error: color(red),
    warning: color(yellow),
    valid: color(kelly-green),
    error-text: $color-error-text-dark,
    valid-text: $color-valid-text-dark,
    input-text: color(darker-gray),
    input-disabled-text: $color-disabled-text-dark,
    input-disabled-background: color(lightest-gray),
    input-disabled-placeholder: color(dark-gray),
    search-minimal-placeholder: darken(color(dark-gray), 10%),

    link-text: color(medium-blue),
    link-underline: color(sky-blue),
    link-hover-text: $color-light-blue-contrast,
    link-hover-underline: color(white),
    link-alternate-text: color(medium-blue),
    link-alternate-hover-text: color(light-blue),

    button-text: color(white),
    button-background: color(light-blue),
    button-border: color(light-blue),
    button-hover-text: color(white),
    button-hover-background: color(dark-blue),
    button-hover-border: color(dark-blue),
    button-alternate-text: color(white),
    button-alternate-background: color(medium-blue),
    button-alternate-border: color(medium-blue),
    button-alternate-hover-text: color(darker-gray),
    button-alternate-hover-background: color(yellow),
    button-alternate-hover-border: color(yellow),
    button-disabled-text: $color-disabled-text-dark,
    button-disabled-background: color(lightest-gray),
    button-disabled-border: color(light-gray),

    texture-grid-opacity: 0.6,
    texture-grid-invert: true,

    superhead: color(darker-gray),
    subhead: color(darker-gray),

    collection-icon-first: color(light-blue),
    collection-icon-second: color(sea-green),
    collection-icon-third: color(grape),
    collection-icon-fourth: color(orange),

    page-header-line: color(red)
  ),

  lightest-yellow: (
    background: color(lightest-yellow),
    text: color(darker-gray),
    accent-text: color(light-blue),
    utility-text: darken(color(dark-gray), 5%),
    shade-background: rgba(color(dark-gray), 0.1),
    shade-background-alternate: rgba(color(dark-gray), 0.1),

    utility-line: color(medium-gray),
    accent-line: color(red),
    accent-line-secondary: color(yellow),
    accent-line-tertiary: color(sky-blue),

    error: color(red),
    warning: color(gold),
    valid: color(kelly-green),
    error-text: $color-error-text-dark,
    valid-text: $color-valid-text-dark,
    input-text: color(darker-gray),
    input-disabled-text: $color-disabled-text-dark,
    input-disabled-background: color(lightest-gray),
    input-disabled-placeholder: color(dark-gray),
    search-minimal-placeholder: darken(color(dark-gray), 10%),

    link-text: color(medium-blue),
    link-underline: color(sky-blue),
    link-hover-text: $color-light-blue-contrast,
    link-hover-underline: lighten(color(lightest-yellow), 8%),
    link-alternate-text: color(medium-blue),
    link-alternate-hover-text: color(light-blue),

    button-text: color(white),
    button-background: color(light-blue),
    button-border: color(light-blue),
    button-hover-text: color(white),
    button-hover-background: color(dark-blue),
    button-hover-border: color(dark-blue),
    button-alternate-text: color(white),
    button-alternate-background: color(medium-blue),
    button-alternate-border: color(medium-blue),
    button-alternate-hover-text: color(white),
    button-alternate-hover-background: color(dark-blue),
    button-alternate-hover-border: color(dark-blue),
    button-disabled-text: $color-disabled-text-dark,
    button-disabled-background: color(lightest-gray),
    button-disabled-border: color(lightest-gray),

    texture-grid-opacity: 0.4,
    texture-grid-invert: true,

    superhead: color(darker-gray),
    subhead: color(darker-gray),

    collection-icon-first: color(light-blue),
    collection-icon-second: color(sea-green),
    collection-icon-third: color(grape),
    collection-icon-fourth: color(orange),

    page-header-line: color(red)
  ),

  lightest-blue: (
    background: color(lightest-blue),
    text: color(darker-gray),
    accent-text: color(light-blue),
    utility-text: darken(color(dark-gray), 5%),
    shade-background: rgba(color(dark-gray), 0.1),
    shade-background-alternate: rgba(color(dark-gray), 0.1),

    utility-line: color(light-gray),
    accent-line: color(red),
    accent-line-secondary: color(yellow),
    accent-line-tertiary: color(sky-blue),

    error: color(red),
    warning: color(yellow),
    valid: color(kelly-green),
    error-text: $color-error-text-dark,
    valid-text: $color-valid-text-dark,
    input-text: color(darker-gray),
    input-disabled-text: $color-disabled-text-dark,
    input-disabled-background: color(lightest-gray),
    input-disabled-placeholder: color(dark-gray),
    search-minimal-placeholder: darken(color(dark-gray), 10%),

    link-text: color(medium-blue),
    link-underline: color(sky-blue),
    link-hover-text: $color-light-blue-contrast,
    link-hover-underline: lighten(color(lightest-blue), 3%),
    link-alternate-text: color(medium-blue),
    link-alternate-hover-text: color(light-blue),

    button-text: color(white),
    button-background: color(light-blue),
    button-border: color(light-blue),
    button-hover-text: color(white),
    button-hover-background: color(dark-blue),
    button-hover-border: color(dark-blue),
    button-alternate-text: color(white),
    button-alternate-background: color(medium-blue),
    button-alternate-border: color(medium-blue),
    button-alternate-hover-text: color(white),
    button-alternate-hover-background: color(dark-blue),
    button-alternate-hover-border: color(dark-blue),
    button-disabled-text: $color-disabled-text-dark,
    button-disabled-background: color(lightest-gray),
    button-disabled-border: color(lightest-gray),

    texture-grid-opacity: 1,
    texture-grid-invert: true,

    superhead: color(darker-gray),
    subhead: color(darker-gray),

    collection-icon-first: color(light-blue),
    collection-icon-second: color(sea-green),
    collection-icon-third: color(grape),
    collection-icon-fourth: color(orange),

    page-header-line: color(yellow)
  ),

  yellow: (
    background: color(yellow),
    text: color(darker-gray),
    accent-text: color(dark-blue),
    utility-text: color(darker-gray),
    shade-background: rgba(color(dark-gray), 0.1),
    shade-background-alternate: rgba(color(dark-gray), 0.1),

    utility-line: rgba(color(white), 0.25),
    accent-line: rgba(color(white), 0.40),
    accent-line-secondary: color(emory-blue),
    accent-line-tertiary: color(emory-blue),

    error: color(red),
    warning: color(gold),
    valid: color(kelly-green),
    error-text: darken($color-error-text-dark, 7%),
    valid-text: darken($color-valid-text-dark, 5%),
    input-text: color(darker-gray),
    input-disabled-text: color(darker-gray),
    input-disabled-background: color(lightest-gray),
    input-disabled-placeholder: color(darker-gray),
    search-minimal-placeholder: color(darker-gray),

    link-text: color(emory-blue),
    link-underline: $color-faux-alpha-white-yellow,
    link-hover-text: color(dark-blue),
    link-hover-underline: $color-faux-alpha-white-yellow,
    link-alternate-text: color(medium-blue),
    link-alternate-hover-text: color(light-blue),

    button-text: color(white),
    button-background: color(light-blue),
    button-border: color(light-blue),
    button-hover-text: color(white),
    button-hover-background: color(dark-blue),
    button-hover-border: color(dark-blue),
    button-alternate-text: color(white),
    button-alternate-background: color(medium-blue),
    button-alternate-border: color(medium-blue),
    button-alternate-hover-text: color(white),
    button-alternate-hover-background: color(dark-blue),
    button-alternate-hover-border: color(dark-blue),
    button-disabled-text: $color-disabled-text-dark,
    button-disabled-background: color(lightest-gray),
    button-disabled-border: color(lightest-gray),

    texture-grid-opacity: 0.4,
    texture-grid-invert: true,

    superhead: color(darker-gray),
    subhead: color(darker-gray),

    collection-icon-first: color(light-blue),
    collection-icon-second: color(sea-green),
    collection-icon-third: color(grape),
    collection-icon-fourth: color(orange),

    page-header-line: color(red)
  ),

  emory-blue: (
    background: color(emory-blue),
    text: color(white),
    accent-text: color(sky-blue),
    utility-text: color(lightest-blue),
    shade-background: rgba(color(white), 0.1),
    shade-background-alternate: rgba(color(white), 0.1),

    utility-line: rgba(color(white), 0.25),
    accent-line: rgba(color(white), 0.25),
    accent-line-secondary: color(yellow),
    accent-line-tertiary: color(sky-blue),

    error: color(red),
    warning: color(yellow),
    valid: color(kelly-green),
    error-text: $color-error-text-light,
    valid-text: $color-valid-text-light,
    input-text: color(darker-gray),
    input-disabled-text: color(lightest-gray),
    input-disabled-background: color(lightest-gray),
    input-disabled-placeholder: color(dark-gray),
    search-minimal-placeholder: color(light-gray),

    link-text: color(yellow),
    link-underline: $color-faux-alpha-white-emory-blue,
    link-hover-text: color(white),
    link-hover-underline: color(light-blue),
    link-alternate-text: color(white),
    link-alternate-hover-text: color(yellow),

    button-text: color(white),
    button-background: transparent,
    button-border: color(white),
    button-hover-text: color(darker-gray),
    button-hover-background: color(yellow),
    button-hover-border: color(yellow),
    button-alternate-text: color(white),
    button-alternate-background: color(light-blue),
    button-alternate-border: color(light-blue),
    button-alternate-hover-text: color(darker-gray),
    button-alternate-hover-background: color(yellow),
    button-alternate-hover-border: color(yellow),
    button-disabled-text: $color-disabled-text-dark,
    button-disabled-background: color(lightest-gray),
    button-disabled-border: color(lightest-gray),

    texture-grid-opacity: 0.1,
    texture-grid-invert: true,

    superhead: color(sky-blue),
    subhead: color(white),

    collection-icon-first: color(light-blue),
    collection-icon-second: color(red),
    collection-icon-third: color(orange),
    collection-icon-fourth: color(sea-green),

    page-header-line: color(yellow)
  ),

  darkest-gray: (
    background: color(darkest-gray),
    text: color(white),
    accent-text: color(sky-blue),
    utility-text: color(lightest-gray),
    shade-background: rgba(color(white), 0.1),
    shade-background-alternate: rgba(color(white), 0.1),

    utility-line: rgba(color(white), 0.25),
    accent-line: rgba(color(white), 0.25),
    accent-line-secondary: color(yellow),
    accent-line-tertiary: color(sky-blue),

    error: color(red),
    warning: color(yellow),
    valid: color(kelly-green),
    error-text: $color-error-text-light,
    valid-text: $color-valid-text-light,
    input-text: color(darker-gray),
    input-disabled-text: color(lightest-gray),
    input-disabled-background: color(lightest-gray),
    input-disabled-placeholder: color(dark-gray),
    search-minimal-placeholder: color(light-gray),

    link-text: color(yellow),
    link-underline: $color-faux-alpha-white-emory-blue,
    link-hover-text: color(white),
    link-hover-underline: color(light-blue),
    link-alternate-text: color(white),
    link-alternate-hover-text: color(yellow),

    button-text: color(white),
    button-background: transparent,
    button-border: color(white),
    button-hover-text: color(darker-gray),
    button-hover-background: color(yellow),
    button-hover-border: color(yellow),
    button-alternate-text: color(white),
    button-alternate-background: color(light-blue),
    button-alternate-border: color(light-blue),
    button-alternate-hover-text: color(darker-gray),
    button-alternate-hover-background: color(yellow),
    button-alternate-hover-border: color(yellow),
    button-disabled-text: $color-disabled-text-dark,
    button-disabled-background: color(lightest-gray),
    button-disabled-border: color(lightest-gray),

    texture-grid-opacity: 0.1,
    texture-grid-invert: true,

    superhead: color(white),
    subhead: color(white),

    collection-icon-first: color(light-blue),
    collection-icon-second: color(red),
    collection-icon-third: color(orange),
    collection-icon-fourth: color(sea-green),

    page-header-line: color(yellow)
  )
);




