.meta {

  & > * {
    margin-bottom: component-spacing(2xs);
  }

  time,
  .daterange {
    display: inline-block;
  }

  cite {
    font-style: normal;
    font-family: $font-primary;
    @include rr-font-compute(s);
    margin-left: 0;
    .title {
      &:before {
        content: 'By\00a0';
        margin: 0;
      }
    }
  }
  a.meta__location {
    display: block;
    &:not(:first-child) {
      @include rr-break-directive(m) {
        margin-left: component-spacing(xs);
        display: inline;
      }
    }
    .icon {
      align-items: baseline;
      .icon__glyph {
        margin-right: 0.3em;
      }
    }
  }

  &.meta--inline {
    @include pattern-stack-margin;
    & > * {
      display: inline;
      @include slash-after;
    }
    cite {
      .title {
        display: inline;
      }
    }
    a.meta__location {
      margin-left: 0;
      &:after {
        display: none;
      }
    }
  }
}

// ==============================================
// scheming
@each $scheme-name, $scheme in $schemes {
  @include scheme-pattern($scheme-name, $scheme) {
    .meta {
      &.meta--inline {
        & > * {
          &:after {
            color: color(accent-text, $scheme);
          }
        }
      }
    }
  }
}