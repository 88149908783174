ul, ol {
  @include rr-font-compute(s, m);
  margin-top: 0;
  margin-bottom: component-spacing(m);

  li {
    margin-bottom: $list-regular;
  }
  
  &.list--loose {
    li {
      margin-bottom: $list-loose;
    }
  }
  &.list--tight {
    li {
      margin-bottom: $list-tight;
    }
  }

  &.list--clean {
    @include clean-list;
  }
  &.list--no-indent {
    padding-left: 1.2em;
  }
  &.list--small {
    li {
      @include rr-font-compute(xs, m);
    }
  }
  &.list--series,
  &.list--piped,
  &.list--inline,
  &.list--slash {
    @include clean-list;
    li {
      display: inline;
    }
  }
  &.list--series {
    li::after {
      content: ', ';
    }
    li:last-child::after {
      content: ''
    }
  }
  &.list--piped {
    li::after {
      content: ' | ';
    }

    li:last-child::after {
      content: ''
    }
  }
  &.list--slash {
    & > li {
      @include slash-after;
    }
  }
  &.list--ruled {
    @include clean-list;
    & > li {
      // &:not(:last-child) {
        padding-bottom: component-spacing(s);
        border-bottom-style: solid;
        border-bottom-width: $utility-line-thickness;
        margin-bottom: component-spacing(s);
      // }
    }
  }
  
  &.list--two-column {
    @include rr-break-directive(m) {
      column-count: 2;
    }
  }
  &.list--three-column {
    @include rr-break-directive(m) {
      column-count: 3;
    }
  }
  &.list--two-column,
  &.list--three-column {
    @include rr-break-directive(m) {
      column-gap: 3em;
      li {
        break-inside: avoid;
        // padding instead of margin here,
        // to fix safari's ignoring break-inside foolishness:
        margin-bottom: 0;
        padding-bottom: $list-regular;
      }
      &.list--loose {
        li {
          margin-bottom: 0;
          padding-bottom: $list-loose;
        }
      }
      &.list--tight {
        li {
          margin-bottom: 0;
          padding-bottom: $list-tight;
        }
      }
    }
  }
  &.list--zebra {
    @include clean-list;
    @include pattern-stack-margin;
    border-width: $accent-line-thickness;
    border-style: solid;
    & > li {
      @include nested-box-padding;
      margin: 0;
      & > *:last-child {
        margin-bottom: 0;
      }
    }
  }
}

// ==============================================
// nested lists
ul ul,
ol ol {
  margin-top: $list-regular;
  margin-bottom: 0;
}

ul.list--loose ul,
ol.list--loose ol {
  margin-top: $list-loose;
}

ul.list--tight ul,
ol.list--tight ol {
  margin-top: $list-tight;
}



// ==============================================
// scheming
@each $scheme-name, $scheme in $schemes {
  @include scheme-pattern($scheme-name, $scheme) {
    ul,
    ol {
      &.list--slash {
        li {
          &:after {
            color: color(accent-text, $scheme);
          }
        }
      }
      &.list--ruled {
        & > li {
          border-bottom-color: color(utility-line, $scheme);
        }
      }
      &.list--zebra {
        border-color: color(shade-background-alternate, $scheme);
        & > li:nth-child(odd) {
          background-color: color(shade-background-alternate, $scheme);
        }
      }
    }
  }
}