.collection.collection--fancy {

  // tighten the grid gap
  .grid:not(.box) {
    @include grid-gutter-fallback($grid-gap-fancy-collection, $grid-bottom-margin: component-spacing(m));
    @supports(display: grid) {
      grid-gap: $grid-gap-fancy-collection;
      margin-bottom: 0;
    }
  }

  & > .grid.grid--major-left {

    // unset any conflicting pattern defaults on children
    & > .box,
    & > .testimonial,
    & > .grid {
      margin-bottom: 0;
    }

    & > :first-child {
      // universal non grid styles here only pls
      .box__content {
        h1, h2, h3, h4, h5, h6 {
          @include rr-heading(h1, $heading-styles);
        }
      }
    }

    // on large screens, first child is overlapped by the others.
    // No overlap on ie11/non-grid browsers
    @include rr-break-directive(l) {
      @supports(display: grid) {
        // these items overlap the first box
        & > * {
          // create overlap
          position: relative;
          z-index: 2;
          margin-left: -($fancy-collection-overlap);
        }

        // make the first item bigger and below the others
        & > :first-child {
          grid-row: 1 / 3;
          z-index: 1;
          margin-left: 0;
          margin-right: -($fancy-collection-overlap/4);
          // keep content from being hidden by the overlap:
          // half amount overlapped + this item's offset margin + this item's padding
          padding-right: calc((#{$fancy-collection-overlap}/2) + (#{$fancy-collection-overlap}/4) + #{component-spacing(m)});

          // bump content to bottom
          display: flex;
          flex-flow: column nowrap;
          justify-content: flex-end;
        }

        // keep the overlap items from touching the edges/shorter than the first item
        & > :nth-child(2) {
          margin-top: component-spacing(2xl);
        }
        & > :nth-child(3) {
          margin-bottom: component-spacing(2xl);
        }
      }
    }

    // let the last multi-column item span both cols in medium screens,
    // when the overlap stops but there's still a 2x2 grid
    @media screen and (min-width: $break-small) and (max-width: $break-medium) {
      & > .grid.grid--major-left:last-child,
      & > .grid.grid--major-right:last-child {
        grid-column: 1 / 3;
      }
    }
  }
}

// ==============================================
// sidebar behavior
// this component really shouldn't ever go in the sidebar
// but just in case
.grid.sidebar .grid__sidebar {
  .collection.collection--fancy {
    & > .grid.grid--major-left > * {
      @supports(display: grid) {
        margin: 0;
      }
    }
  }
}