// the header is not schemeable, all colors are fixed.
header.header {
  position: relative;
  z-index: 1;
  margin-bottom: component-spacing(m);
  .slab {
    padding: 0;
    .slab__wrapper {
      .slab__content {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: space-between;
        .header__logo {
          background-color: color(emory-blue);
          position: relative;
          padding: component-spacing(s);
          a {
            @include faux-underline($underline-color: transparent);
            display: inline-block;
            width: rem(150px);
            // prevent false positive on contrast test
            // the logo is white but the screenreader label inside the link is not.
            color: #fff;
            &:link,
            &:visited,
            &:hover,
            &:active,
            &:focus {
              color: #fff;
            }
          }
        }
        .toggle-button {
          align-self: center;
          background-color: #fff;
          border-color: #fff;
          color: color(emory-blue);
          width: rem(85px);
          .icon {
            .icon__label {
              @include rr-font-compute(xs, m);
              text-transform: uppercase;
            }
            &.icon--stacked {
              // special hamburger icon has a different scale than the others
              .icon__glyph.ic--hamburger {
                font-size: 1rem;
                margin-top: rem(9px);
                margin-bottom: rem(9px);
              }
            }
          }
        }
        .header__nav {
          display: none;
          flex-flow: column nowrap;
          width: 100%;
          .global-nav,
          .utility-nav {
            padding: component-spacing(s);
          }
          .global-nav {
            .link-list {
              ul {
                li:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
          .utility-nav {
            background-color: color(lightest-gray);
            ul {
              align-items: center;
            }
            @include break-max($break-medium) {
              .search {
                form {
                  input {
                    background-color: #fff;
                  }
                  button,
                  .button {
                    background-color: #fff;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@include rr-break-directive(l) {
  header.header {
    margin-bottom: 0;
    .slab {
      padding: {
        top: component-spacing(m);
        right: component-spacing(m);
        bottom: 0;
        left: component-spacing(m);
      }
      .slab__wrapper {
        .slab__content {
          flex-flow: row nowrap;
          .header__logo {
            padding: {
              right: component-spacing(m);
              bottom: component-spacing(m)*1.5;
            }
            margin-right: component-spacing(m);
            a {
              width: rem(150px);
            }
            &:after {
              display: block;
              content: '';
              position: absolute;
              top: -999999px;;
              bottom: -(component-spacing(2xl));
              right: 0;
              left: -999999px;
              background-color: color(emory-blue);
              z-index: 0;
            }
          }
          .toggle-button {
            display: none;
          }
          .header__nav {
            // using important here to ensure menu stays open in large screens
            // if user manually scales the page size up
            // after toggling off the menu in a smaller screen size
            display: flex !important;
            flex: 1 1 auto;
            .global-nav,
            .utility-nav {
              padding: 0;
            }
            .global-nav {
              order: 2;
              .link-list {
                ul {
                  li {
                    margin-bottom: 0;
                  }
                }
              }
            }
            .utility-nav {
              order: 1;
              align-self: flex-end;
              margin-bottom: component-spacing(m);
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}

@include rr-break-directive(xl) {
  header.header {
    .slab {
      .slab__wrapper {
        .slab__content {
          .header__logo {
            padding: {
              right: component-spacing(xl);
              bottom: component-spacing(m)*1.5;
            }
            margin-right: component-spacing(m);
            a {
              width: rem(230px);
            }
          }
          .header__nav {
            .utility-nav {
              margin-bottom: component-spacing(xl);
            }
          }
        }
      }
    }
  }
}

@include break-max($break-xsmall)  {
  header.header {
    .slab {
      .slab__wrapper {
        .slab__content {
          .header__logo {
            flex: 1;
            margin-right: component-spacing(xs);
            a {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

// flexbox wrap fix for ie11
// insert facepalm emoji
@include rr-break-directive(l) {
  header.header {
    .slab {
      .slab__wrapper {
        .slab__content {
          .header__nav {
            width: 100%;
            .utility-nav {
              width: 100%;
              .link-list {
                width: 100%;
                ul {
                  width: 100%;
                  li {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @supports(display: grid) {
    header.header {
      .slab {
        .slab__wrapper {
          .slab__content {
            .header__nav {
              width: auto;
              .utility-nav {
                width: auto;
                .link-list {
                  width: auto;
                  ul {
                    width: auto;
                    li {
                      width: auto;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}