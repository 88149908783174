@charset 'UTF-8';

// These settings override the defaults found in
// ../bower_components/slick-carousel/slick/slick-theme.scss

$slick-font-path: '//cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.5.9/fonts/';
$slick-loader-path: './js/slick/' !default;
$slick-arrow-color: color('newcity-black');
$slick-button-distance: -10px;
$slick-dot-size: .5rem !default;
//$slick-font-family: "slick" !default;
//$slick-dot-color: black !default;
//$slick-dot-color-active: $slick-dot-color !default;
//$slick-prev-character: "←" !default;
//$slick-next-character: "→" !default;
//$slick-dot-character: "•" !default;
//$slick-opacity-default: 0.75 !default;
//$slick-opacity-on-hover: 1 !default;
//$slick-opacity-not-active: 0.25 !default;

.slick-loading .slick-list {
  background: color(newcity-white) url('./js/libs/slick/ajax-loader.gif') center center no-repeat;
}
