.sidebar.grid {

  // visually flip the layout on all screens
  &.sidebar--reverse {
    .grid__main {
      order: 2;
    }
    .grid__sidebar {
      order: 1;
    }
  }

  // put the sidebar first for mobile reasons,
  // usually when the sidebar contains filters that filter the main column
  // but use the default layout on large screens.
  &.sidebar--mobile-reverse {
    @include break-max($break-sidebar) {
      grid-gap: $grid-gap/2;
    }
    .grid__main {
      order: 2;
      @include break-min($break-sidebar) {
        order: 1;
      }
    }
    .grid__sidebar {
      order: 1;
      @include break-min($break-sidebar) {
        order: 2;
      }
    }
  }

  @include break-min($break-sidebar) {
    &.sidebar--major-left {
      > *:nth-child(odd) {
        flex-basis: grid-cell-fallback($sidebar-major-size, $grid-gap);
      }
      > *:nth-child(even) {
        flex-basis: grid-cell-fallback(uneven-grid-remainder($sidebar-major-size), $grid-gap);
      }
      @supports(display: grid) {
        grid-template-columns: minmax(min-content, $sidebar-major-size) minmax($sidebar-min-width, 1fr);
      }
    }
    &.sidebar--major-right {
      > *:nth-child(odd) {
        flex-basis: grid-cell-fallback(uneven-grid-remainder($sidebar-major-size), $grid-gap);
      }
      > *:nth-child(even) {
        flex-basis: grid-cell-fallback($sidebar-major-size, $grid-gap);
      }
      @supports(display: grid) {
        grid-template-columns: minmax($sidebar-min-width, 1fr) minmax(min-content, $sidebar-major-size);
      }
    }
  }
}

// sidebar layout breaks to single column sooner than standard major/minor grids
.sidebar.grid,
.sidebar.grid.sidebar--major-left,
.sidebar.grid.sidebar--major-right {
  @include break-max($break-sidebar) {
    grid-template-columns: 1fr;
    .grid__main,
    .grid__sidebar,
    > * {
      flex-basis: 100%;
    }
  }
}

// flex in ie11 behaves weird when the sidebar is inside a slab (so, all sidebar pages)
.slab .sidebar.grid {
  flex-flow: row nowrap;
  @include break-max($break-sidebar) {
    flex-flow: row wrap;
  }

  @include break-min($break-sidebar) {
    &.sidebar--major-left {
      > *:nth-child(odd) {
        flex: 1 1 auto;
      }
      > *:nth-child(even) {
        flex: 1 1 auto;
        flex-basis: grid-cell-fallback(uneven-grid-remainder($sidebar-major-size), $grid-gap);
      }
    }
  }
}

