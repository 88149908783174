.slab.page-header {
  padding-top: 0;
  @include break-min($break-xsmall) {
    padding-bottom: 0;
  }

  // stay above the logo container on large screens
  @include rr-break-directive(l) {
    position: relative;
    z-index: 2;
  }

  .page-header__figure {
    figure {
      margin-bottom: 0;
    }
  }

  // page header has more padding than default schemed boxes
  .box.page-header__box {
    @include break-min($break-page-header) {
      padding: $page-header-padding;
    }
  }

  .box__content {
    h1,
    h1.headline-group,
    h1.headline-group .headline-group__head,
    .headline-group.headline-group--h1 .headline-group__head {
      margin-bottom: 0;
      @include break-max($break-xsmall) {
        @include rr-font-compute(5xl, m);
      }
    }
    & > p {
      margin-top: 0;
    }
    .page-header__figure,
    .link-list--audience {
      @include break-max($break-page-header) {
        margin-top: component-spacing(m);
      }
    }
  }

  // option to scale down the headline size - so far only needed for the homepage
  // but also useful for many cases of page-header--major-left and --major-right,
  // depending on the other children.
  &.page-header--small-headline {
    .box__content {
      align-items: center;
      h1,
      h1.headline-group,
      h1.headline-group .headline-group__head,
      .headline-group.headline-group--h1 .headline-group__head {
        // this size is really specific and falls out of the existing typescale for some reason
        font-size: rem(40px);
        line-height: rem(46px);
        @include break-max($break-xsmall) {
          @include rr-font-compute(5xl, m);
        }
      }
    }
  }

  // grid layout for large screens:
  // in page headers, all children in .box__content flow normally
  // except for the image and the audience link list, 
  // which get 50% of the available area (less depending on variants)

  // fallback:
  // ie/non-grid supporters get a similar layout - not an exact match
  // depending on what children are in the page header (or how many)

  @include break-min($break-page-header) {
    .page-header__box > .box__content {

      // first, the fallback
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      & > * {
        flex: 1 1 auto;
      }
      .page-header__figure,
      .link-list--audience {
        flex: 0 1 calc(50% - #{$grid-gap});
      }

      // then, grid
      @supports(display: grid) {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
        grid-column-gap: $grid-gap;
        & > * {
          // if it's not image or audience nav,
          // force start in col 1 
          grid-column-start: 1;
        }
        .page-header__figure,
        .link-list--audience {
          grid-column: 2 / 2;
          grid-row: 1 / 99;
        }
      }
    }
  }

  // on large screens that support the fancy grid,
  // touch the image to the edge of the box
  // (unless explicitly told not to by variant)
  @include break-min($break-page-header) {
    @supports(display: grid) {
      &:not(.page-header--simple-image) {
        .page-header__box > .box__content {
          .page-header__figure {
            margin: -(component-spacing(l));
            margin-left: 0;
            position: relative;
            min-height: rem(350px); // show enough of the image if there's only title/short content
            figure {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              margin: auto;
              img {
                object-fit: cover;
                object-position: center;
                font-family: "object-fit: cover; object-position: center";
              }
            }
          }
        }
      }
    }
  }

  // options to change the column layout from 50/50
  @include break-min($break-page-header) {
    @supports(display: grid) {
      &.page-header--major-left {
        .page-header__box > .box__content {
          grid-template-columns: $page-header-uneven-major-size 1fr;
        }
      }
      &.page-header--major-right {
        .page-header__box > .box__content {
          grid-template-columns: 1fr $page-header-uneven-major-size;
          p {
            grid-column: 2 / 2;
            grid-row: 1 / 99;
          }
        }
      }
    }
  }

  // the heading should get the fancy floating line any time there's something below it,
  // unless the thing below it is audience nav or an image,
  // OR unless the thing below it is displaced by the grid layout in some way.
  // it's complicated.
  .page-header__box {
    .box__content {
      h1,
      .headline-group--h1 .headline-group__head {
        & + * {
          &:not(.page-header__figure):not(.link-list--audience) {
            @include floating-line($property: 'before');
            margin-top: component-spacing(m);
            &.rating {
              position: relative;
              padding-top: component-spacing(s);
              &:before {
                position: absolute;
                top: 0;
              }
            }
          }
        }
      }
    }
  }
  // if using the major-right variant, put the line on anything that 
  // follows the paragraph instead - since major-left forces the 
  // paragraph (if it exists) to the 2nd column.
  // BUT only do this on large screens that support the grid layout,
  // and still not on images or audience nav.
  @include break-min($break-page-header) {
    @supports(display: grid) {
      &.page-header--major-right {
        .box__content {
          h1,
          .headline-group--h1 .headline-group__head {
            & + p {
              &:not(.page-header__figure):not(.link-list--audience) {
                margin-top: 0;
              }
              &:after,
              &:before {
                display: none !important; // really don't put a line here, okay?
              }
              & + * {
                &:not(.page-header__figure):not(.link-list--audience) {
                  @include floating-line($property: 'before');
                }
              }
            }
          }
        }
      }
    }
  }

  // option to combine with the box--texture-image-overlay variant
  // (put the image behind the page header content as a texture instead of in the grid)
  .page-header__box.box--texture-image-overlay > .box__content {
    // de-grid, add some room to see the image
    display: block;
    padding-top: component-spacing(xl);
    padding-bottom: component-spacing(xl);
    .page-header__figure {
      position: absolute;
      min-height: 0;
    }
  }
  @include break-max($break-page-header) {
    .page-header__box.box--texture-image-overlay {
      & > .box__content {
        .page-header__figure {
          figure {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
          }
        }
      }
    }
  }

  // option to offset an item and overlap it with the box
  // the item must go in .box__footer
  @include break-min($break-page-header) {
    &.page-header--offset {
      .slab__content {
        position: relative;
      }
      .page-header__box {
        position: static;
        width: $page-header-offset-major-size;
        & > .box__content {
          display: block;
          padding-right: calc((#{uneven-grid-remainder($page-header-offset-major-size)}/2) + (#{$page-header-padding} * 3.25));
          // adjust image when offset is combined with the image overlay option
          .page-header__figure {
            margin: 0;
            width: calc(#{$page-header-offset-major-size} + #{$page-header-padding * 2});
          }
        }
        & > .box__footer {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: auto;
          width: calc(#{uneven-grid-remainder($page-header-offset-major-size)} * 1.5);
          margin: 0;
          display: flex;
          flex-flow: column nowrap;
          justify-content: center;
          & > *:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  // tweaks when combined with the image overlay box style
  .box.page-header__box.box--texture-image-overlay {
    & > .box__content {
      h1,
      .headline-group--h1{
        .headline-group__sub {
          font-weight: 500;
          font-family: $font-tertiary;
          @include rr-font-compute(4xl, m);
        }
      }
    }
  }
}


// ==============================================
// scheming
@each $scheme-name, $scheme in $schemes {
  @include scheme-pattern($scheme-name, $scheme, 'page-header__box') {
    .box__content {
      & > * {
        &:before,
        &:after {
          background-color: color(page-header-line, $scheme);
        }
      }
    }
  }
}