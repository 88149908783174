p {
  @include rr-font-compute(s, m);
  margin-bottom: component-spacing(m);

  &.p--large {
    @include rr-font-compute(2xl, m);
    /* this was an accessibility change requested by the client; not really needed, but if they want it then uncomment: */
    // line-height: 1.5 !important;
  }

  &.p--small {
    @include rr-font-compute(xs, m);
  }

  &.p--ruled {
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    &:before,
    &:after {
      content: '';
      display: inline-block;
      height: $accent-line-thickness;
      flex: 0 1 ($floating-line-width / 4);
    }
    &:before {
      margin-right: 0.5em;
    }
    &:after {
      margin-left: 0.5em;
    }
  }
}

// ==============================================
// scheming
@each $scheme-name, $scheme in $schemes {
  @include scheme-pattern($scheme-name, $scheme) {
    p {
      &.p--ruled {
        &:before,
        &:after {
          background-color: color(text, $scheme);
        }
      }
    }
  }
}