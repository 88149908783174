.global-nav {
  .link-list {
    ul {
      li {
        text-transform: uppercase;
        font-weight: bold;
      }
    }
  }
  @include rr-break-directive(l) {
    .link-list {
      ul {
        justify-content: space-between;
        li {
          flex: 0 1 16%;
          display: flex;
          a {
            display: inline-block;
            width: 100%;
            padding-bottom: component-spacing(s);
            &:before,
            &:after {
              display: block;
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              height: $accent-line-thickness;
            }
            &:before {
              right: 0;
              background-color: color(lightest-gray);
              z-index: 1;
            }
            &:after {
              right: auto;
              width: rem(40px);
              background-color: color(red);
              transition: width $transition-time $transition-easing;
              z-index: 2;
            }
            &:hover,
            &:active,
            &:focus {
              text-decoration: none;
              &:after {
                width: 100%;
              }
            }
          }
          &.global-nav--active {
            a {
              &:after {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

// ==============================================
// scheming
// global nav has no real scheme settings, 
// but it should not have faux-underlines on links
// since they have their own special link indicators
@each $scheme-name, $scheme in $schemes {
  @include scheme-pattern($scheme-name, $scheme) {
    .global-nav {
      a {
        @include faux-underline($underline-color: transparent);
      }
    }
  }
}