.section-intro {
  // always put the bottom margin on section intro,
  // even if it's the last child (unlike other patterns that use the pattern-stack-margin mixin)
  // since it often appears in __intro containers preceding collections & grids
  margin-bottom: component-spacing(m);
  :last-child {
    margin-bottom: 0;
  }

  &.section-intro--center {
    text-align: center;
    .heading--fancy {
      &:after {
        margin-right: auto;
        margin-left: auto;
      }
    }
  }

  @include rr-break-directive(l) {
    &.section-intro--narrow,
    &.section-intro--narrow-center {
      max-width: $grid-uneven-major-size;
    }
    &.section-intro--narrow.section-intro--center,
    &.section-intro--narrow-center {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &.section-intro--row {
    @include rr-break-directive(l) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .headline-group {
        flex: 1 1 auto;
      }
      p {
        flex: 0 1 40%;
        margin-left: component-spacing(m);
      }
      & > a,
      & > .link-list {
        @include faux-underline($underline-color: transparent);
        flex: 0 1 auto;
        max-width: 25%;
        min-width: 10%;
        margin-left: component-spacing(m);
        justify-content: flex-end;
      }
    }
  }
}

// when section intro is in a grid, remove the margin and let grid control the spacing.
// allows section intro to keep its standalone introductory properties (always has margin even if it's last child)
.grid > .section-intro,
.grid.box > .box__intro > .section-intro:last-child {
  margin-bottom: 0;
}