.box {
  @include pattern-stack-margin;

  &.box--frame {
    border-width: $accent-line-thickness;
    border-style: solid;
    padding: component-spacing(s);
  }

  &.box--constrain {
    @include break-min($break-medium) {
      .box__content,
      .box__footer,
      .box__intro {
        max-width: $grid-uneven-major-size;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &.box--center {
    text-align: center;
    .heading--fancy {
      &:after {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &.box--center-vertical {
    @include vertically-center-contents;
  }

  &.box--simple {
    .box__intro {
      .section-intro {
        margin-bottom: component-spacing(s);
        .headline-group {
          @include text-simple-heading;
        }
      }
    }
    .box__content {
      p {
        @include rr-font-compute(xs, m);
      }
    }
  }

  &.box--texture-grid {
    @include texture-grid;
  }

  &.box--content-first,
  &.box--footer-first {
    @include rr-break-directive(m) {
      &:not(.grid) {
        display: flex;
        flex-flow: row wrap;
        align-content: flex-start;
        .box__intro,
        .box__content,
        .box__footer {
          flex: 1 1 100%;
        }
      }
      .box__intro {
        order: 2;
      }
      .box__content {
        order: 1;
        margin-bottom: component-spacing(m);
      }
      .box__footer {
        order: 3;
      }
    }
  }

  &.box--footer-first {
    &:not(.grid) {
      display: flex;
      flex-flow: row wrap;
      align-content: flex-start;
      .box__intro,
      .box__content,
      .box__footer {
        flex: 1 1 100%;
      }
    }
    .box__intro {
      order: 2;
    }
    .box__content {
      order: 3;
    }
    .box__footer {
      order: 1;
    }
  }

  &.box--footer-popout {
    &[class*=scheme--] {
      .box__footer {
        margin: -(component-spacing(s));
        margin-top: component-spacing(s);
        @include rr-break-directive(l) {
          margin: -(component-spacing(m));
          margin-top: component-spacing(m);
        }
      }
      &.box--footer-first {
        .box__footer {
          margin: -(component-spacing(s));
          margin-bottom: component-spacing(s);
          @include rr-break-directive(l) {
            margin: -(component-spacing(m));
            margin-bottom: component-spacing(m);
          }
        }
      }
    }
  }

  &.box--texture-image-overlay {
    &:not(.box--square) {
      @include nested-box-padding;
    }
    position: relative;
    overflow: hidden;
    background-color: color(darkest-gray);
    .box__intro,
    .box__footer {
      position: relative;
      z-index: 2;
    }
    .box__content {
      & > figure,
      & > img,
      & > .page-header__figure {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 1;
        object-fit: cover;
        object-position: center;
        font-family: "object-fit: cover; object-position: center;";
        margin-bottom: 0;
        opacity: 0.2;
        img {
          object-fit: cover;
          object-position: center;
          font-family: "object-fit: cover; object-position: center;";
        }
      }
      // fix IE11 offset page header overflow (issue #27)
      & > .page-header__figure {
        overflow: hidden;
      }
      & > * {
        position: relative;
        z-index: 2;
      }
    }
  }

  &.box--square {
    @include break-min($break-square) {
      width: 100%;
      padding-bottom: 100%;
      position: relative;
      .box__content {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-end;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
      }
    }
    &.box--texture-image-overlay {
      .box__content {
        & > figure,
        & > img {
          @include break-min($break-square) {
            margin: -(component-spacing(s));
          }
          @include rr-break-directive(l) {
            margin: -(component-spacing(m));
          }
        }
      }
    }
  }
}


// no bottom margins on box if it's being used as the items in a grid,
// since all the spacing should come from the grid's gutter
.grid > .box,
.grid > .box.grid {
  @include rr-break-directive(m) {
    margin-bottom: 0;
  }
}

// fix IE11 image-size/spacing problem on "lung-cancer" variant of "pages/type" pattern (issue #15)
.grid > .box.box--square.box--texture-image-overlay {
  @include break-min($break-square) {
    padding-bottom: 40%;
    @supports(display: grid) {
      padding-bottom: 100%;
    }
  }
}
.box.box--center-vertical.box--content-first.grid.grid--large {
  flex-direction: row;
  margin-bottom: 0;
  margin-top: 0;
}

// if a box is schemed, put padding on it.
// use margin for square boxes since they rely on a percentage padding hack to force square ratio
.box[class*=scheme--]:not(.box--square) {
  @include nested-box-padding;
}
.box[class*=scheme--].box--square {
  @include nested-box-padding($square: true);
}

// ==============================================
// boxes in sidebars
.grid.sidebar {
  .grid__sidebar {
    // use block buttons in simple box
    .box.box--simple {
      .box__content {
        .button,
        button {
          display: block;
          text-align: center;
        }
      }
    }
    // no square boxes in sidebar column
    .box.box--square {
      width: auto;
      padding-bottom: 0;
      position: relative;
      &[class*=scheme--] {
        @include nested-box-padding;
        & > * {
          margin: 0;
        }
      }
      .box__content {
        display: block;
        position: static;
      }
    }
  }
}

// ==============================================
// scheming
@each $scheme-name, $scheme in $schemes {
  @include scheme-pattern($scheme-name, $scheme) {
    .box {
      &.box--frame {
        border-color: color(accent-line-tertiary, $scheme);
      }
    }
  }
}

@each $scheme-name, $scheme in $schemes {
  @include scheme-pattern($scheme-name, $scheme, 'box--texture-image-overlay') {
    background-color: color(background, $scheme);
  }
}
