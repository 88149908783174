button,
.button {

  // reset
  background: none;
  border: none;
  appearance: none;
  border-radius: 0;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;

  // actual button styles
  padding: $button-padding-top-bottom $button-padding-left-right;
  font-weight: bold;
  @include rr-font-compute(xs, s);
  border-style: solid;
  border-width: $accent-line-thickness;
  transition: all $transition-time $transition-easing;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }

  &[disabled="true"],
  &[disabled="disabled"] {
    cursor: not-allowed;
  }

  &.button--arrow {
    display: inline-flex;
    align-items: center;

    @extend .ic;
    @extend .ic--arrow-right;
    &:before {
      margin-right: component-spacing(xs);
    }
  }

  &.button--block {
    display: block;
    text-align: center;

    &.button--arrow {
      display: flex;
      justify-content: center;
    }
  }
}

// ==============================================
// scheming
@each $scheme-name, $scheme in $schemes {
  @include scheme-pattern($scheme-name, $scheme) {
    button,
    .button {
      @include faux-underline($underline-color: transparent);
      color: color(button-text, $scheme);
      background-color: color(button-background, $scheme);
      border-color: color(button-border, $scheme);
      &:link,
      &:visited {
        color: color(button-text, $scheme);
      }
      &:hover,
      &:active,
      &:focus {
        color: color(button-hover-text, $scheme);
        background-color: color(button-hover-background, $scheme);
        border-color: color(button-hover-border, $scheme);
      }

      &.button--alternate {
        color: color(button-alternate-text, $scheme);
        background-color: color(button-alternate-background, $scheme);
        border-color: color(button-alternate-border, $scheme);
        &:link,
        &:visited {
          color: color(button-alternate-text, $scheme);
        }
        &:hover,
        &:active,
        &:focus {
          color: color(button-alternate-hover-text, $scheme);
          background-color: color(button-alternate-hover-background, $scheme);
          border-color: color(button-alternate-hover-border, $scheme);
        }
      }

      &[disabled="true"],
      &[disabled="disabled"],
      &.button--alternate[disabled="true"],
      &.button--alternate[disabled="disabled"] {
        color: color(button-disabled-text, $scheme);
        background-color: color(button-disabled-background, $scheme);
        border-color: color(button-disabled-border, $scheme);
      }


      // no pattern button styles on the feedback tab
      &[class*=ftbk]{
        border: 1px solid rgba(0,0,0,.1);
        color: #444;
        background-color: #f5f5f5;
        &:link,
        &:visited {
          color: #444;
        }
        &:hover,
        &:active,
        &:focus {
          color: #333;
          border: 1px solid #c6c6c6;
        }
      }
    }
  }
}
