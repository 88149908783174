.link-list {
  .section-intro {
    margin-bottom: component-spacing(s);
  }
  ul {
    li {
      // when using the ruled paragraph as a list separator, 
      // remove its margin and use the li's margin instead
      p.p--ruled {
        margin-bottom: 0;
      }
    }
  }

  ul.list--inline {
    li {
      .button,
      button,
      .link--fancy {
        display: flex;
        margin-bottom: component-spacing(xs);
        @include rr-break-directive(m) {
          display: inline-flex;
          margin-right: component-spacing(s);
        }
      }
      &:last-child {
        .button,
        button {
          margin-right: 0;
        }
      }
    }
  }

  &.link-list--audience {
    ul.grid {
      grid-auto-rows: 1fr;
      // tbd return to weird grid-gap for this pattern later.
      // @include grid-gutter-fallback($grid-gap-fancy-collection, $grid-bottom-margin: component-spacing(m));
      // @supports(display: grid) {
      //   grid-gap: $grid-gap-fancy-collection;
      //   margin-bottom: 0;
      // }
      li {
        display: flex;
        a.button,
        button {
          flex: 1 1 100%;
          display: inline-flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}


// ==============================================
// link-list inside a sidebar layout:
// if the link list is a direct child of __main or __sidebar, 
// assume it's a standalone pattern and put a bottom margin on it.
.grid.sidebar {
  .grid__main,
  .grid__sidebar {
    & > .link-list {
      @include pattern-stack-margin;
    }
  }
}