.rating {
  @include pattern-stack-margin;
  ul.rating__stars {
    @include clean-list;
    display: flex;
    li {
      margin-bottom: 0;
      .icon {
        .icon__glyph {
          margin-right: component-spacing(2xs)/2;
        }
        .icon__label {
          @include visually-hidden;
        }
      }
    }
  }
  p.rating__summary {
    font-weight: bold;
    margin-top: 0;
  }
  @include rr-break-directive(m) {
    display: flex;
    align-items: center;
    ul.rating__stars {
      margin-right: component-spacing(2xs);
    }
    p.rating__summary {
      margin: 0;
    }
  }
}

// ==============================================
// scheming
@each $scheme-name, $scheme in $schemes {
  @include scheme-pattern($scheme-name, $scheme) {
    .rating {
      ul.rating__stars {
        li {
          .icon {
            .icon__glyph {
              color: color(link-text, $scheme);
            }
          }
        }
      }
    }
  }
}