.sep {
  &::before {
    content: '•';
  }
}

.sep--dash {
  &::before {
    content: '-';
  }
}

.sep--pipe {
  &::before {
    content: '|';
  }
}
