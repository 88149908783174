.headline-group {
  span.headline-group__super,
  span.headline-group__head,
  span.headline-group__sub {
    display: block;
  }

  .headline-group__super {
    @include text-tertiary;
    margin-bottom: component-spacing(3xs);
  }

  .headline-group__sub {
    @include rr-font-compute(s, m);
    margin-top: component-spacing(3xs);
  }

  // support nonesemantic headings
  @each $tag, $config in $heading-styles {
    &.headline-group--#{$tag} {
      .headline-group__head {
        @include rr-heading($tag, $heading-styles);
      }
    }
  }

  // stack circle icons when they're used inside a headline group
  .icon--circle {
    display: block;
  }
}


// ==============================================
// scheming

@each $scheme-name, $scheme in $schemes {
  @include scheme-pattern($scheme-name, $scheme) {
    .headline-group {
      span.headline-group__super {
        color: color(superhead, $scheme);
      }
      span.headline-group__sub {
        color: color(subhead, $scheme);
      }
    }
  }
}