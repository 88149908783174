.slick-slider {
  display: block;
  position: relative;
  box-sizing: border-box;
  user-select: none;
  touch-action: pan-y;
}

.slick-list {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  overflow: hidden;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: hand;
  }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  display: block;
  position: relative;
  top: 0;
  left: 0;

  &::before,
  &::after {
    display: table;
    content: '';
  }

  &::after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}

.slick-slide {
  display: none;
  height: 100%;
  min-height: 1px;
  float: left;

  [dir='rtl'] & {
    float: right;
  }

  img {
    display: block;
  }

  &.slick-loading img {
    display: none;
  }

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}

.slick-arrow.slick-hidden {
  display: none;
}
