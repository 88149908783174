.grid {

  // flexbox fallback + global bottom margin for all grid types
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  overflow: hidden;

  // simulate grid-gap by adjusting margins
  // (then unset it when grid is supported)
  @include grid-gutter-fallback($grid-gap, $grid-bottom-margin: component-spacing(m));

  > * {
    width: 100%;
    flex: 1 1 100%;
    @include break-max($break-xsmall) {
      // apparent narrow gutters on very small screens
      margin-bottom: 0;
    }
    @include rr-break-directive(m) {
      flex-basis: grid-cell-fallback(2, $grid-gap);
    }
    @include rr-break-directive(l) {
      flex-basis: grid-cell-fallback($grid-columns-medium, $grid-gap);
    }
  }

  // non-fallback fixed-column grids + grid defaults
  @supports(display: grid) {
    display: grid;
    grid-gap: $grid-gap;
    overflow: visible;
    &:last-child {
      margin-bottom: 0;
    }
    grid-template-columns: repeat(1, 1fr);
    @include break-max($break-xsmall)  {
      grid-gap: $grid-gap/2;
    }
    @include rr-break-directive(m) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include rr-break-directive(l) {
      @include fixed-grid($grid-columns-medium, $grid-gap);
    }
    > * {
      width: auto;
      min-width: 0;
    }
  }

  // spacing control on small screens
  @include break-max($break-small) {
    @include grid-gutter-fallback($grid-gap/2, $grid-bottom-margin: component-spacing(m));
    @supports(display: grid) {
      grid-gap: $grid-gap/2;
      &:last-child {
        margin-bottom: 0;
      }
    }
    & > .box:not(:last-child) {
      margin-bottom: 0;
    }
  }

  // ==============================================
  // variants to change the number of columns
  @include rr-break-directive(l) {
    &.grid--xsmall {
      @include fixed-grid($grid-columns-xsmall, $grid-gap);
    }
    &.grid--small {
      @include fixed-grid($grid-columns-small, $grid-gap);
    }
    &.grid--large {
      @include fixed-grid($grid-columns-large, $grid-gap);
    }
  }
  &.grid--xlarge {
    > * {
      flex-basis: 100%;
    }
    @supports(display: grid) {
      grid-template-columns: 1fr;
    }
  }

  // ==============================================
  // flexible grids
  // prioritize a minimum cell size over number of columns
  &.grid--flexible {
    @include flexible-grid(100%);
    @include rr-break-directive(m) {
      @include flexible-grid($grid-flexible-medium-min);
    }

    &.grid--small {
      @include flexible-grid($grid-flexible-small-min);
    }
    &.grid--large {
      @include rr-break-directive(m) {
        @include flexible-grid($grid-flexible-large-min);
      }
    }
    &.grid--xlarge {
      @include flexible-grid(100%);
    }
  }

  // ==============================================
  // grids with uneven columns
  @include rr-break-directive(l) {
    &.grid--major-left {
      > *:nth-child(odd) {
        flex-basis: grid-cell-fallback($grid-uneven-major-size, $grid-gap);
      }
      > *:nth-child(even) {
        flex-basis: grid-cell-fallback(uneven-grid-remainder($grid-uneven-major-size), $grid-gap);
      }
      @supports(display: grid) {
        grid-template-columns: minmax(min-content, $grid-uneven-major-size) minmax(min-content, 1fr);
      }
    }
    &.grid--major-right {
      > *:nth-child(odd) {
        flex-basis: grid-cell-fallback(uneven-grid-remainder($grid-uneven-major-size), $grid-gap);
      }
      > *:nth-child(even) {
        flex-basis: grid-cell-fallback($grid-uneven-major-size, $grid-gap);
      }
      @supports(display: grid) {
        grid-template-columns: minmax(min-content, 1fr) minmax(min-content, $grid-uneven-major-size);
      }
    }
  }

  // ==============================================
  // use smaller gutters
  &.grid--narrow-gutters {
    @include grid-gutter-fallback($grid-gap-narrow, $grid-bottom-margin: component-spacing(m));
    @supports(display: grid) {
      grid-gap: $grid-gap-narrow;
      &.box {
        @include pattern-stack-margin;
        &:last-child,
        &.grid,
        &.grid--narrow-gutters {
          margin-bottom: 0;
        }
      }
    }
  }

  // ==============================================
  // put ruled dividers between grid items
  // this may not combine well if the items are complex boxes with textures/overlays
  &.grid--ruled {
    > * {
      position: relative;
      &:after {
        // defaults: line below each item on small screens
        @include ruled-grid-line;
        // then between each item on larger screens
        @include rr-break-directive(m) {
          @include ruled-grid-line($horizontal: false);
        }
        // on small screens, grid items have tighter gutters
        @include break-max($break-small)  {
          @include ruled-grid-line($gap: ($grid-gap-narrow + 0.25rem));
        }
      }
    }
    // adjust the line when using narrow gutters
    &.grid--narrow-gutters {
      > * {
        &:after {
          @include ruled-grid-line($gap: $grid-gap-narrow);
          @include rr-break-directive(m) {
            @include ruled-grid-line($gap: $grid-gap-narrow, $horizontal: false);
          }
        }
      }
    }
    // ruled xlarge grids
    // since items in extra large grids are always full-width/stacked,
    // never show vertical lines on them and always show horizontals
    &.grid--xlarge {
      > * {
        &:after {
          @include ruled-grid-line;
          @include break-max($break-xsmall)  {
            @include ruled-grid-line($gap: ($grid-gap-narrow + 0.25rem));
          }
        }
      }
      &.grid--narrow-gutters {
        > * {
          &:after {
            @include ruled-grid-line($gap: $grid-gap-narrow);
          }
          &:last-child {
            &:after {
              display: none;
            }
          }
        }
      }
    }
    > * {
      // never show the line on the last item, regardless of count, columns, or screen size
      &:last-child {
        &:after {
          display: none;
        }
      }
      // on medium-sized screens most grids break to two columns, so no line on the 2nd col
      @include break-range($break-small, $break-medium) {
        &:nth-child(even) {
          &:after {
            display: none;
          }
        }
      }
    }

    // large screens:
    // don't show lines on the last item in each row (right side)
    @include rr-break-directive(l) {
      // default three-col grids -> using not to reduce overwriting on size variants
      &:not(.grid--xsmall):not(.grid--small):not(.grid--large):not(.grid--xlarge) {
        > *:nth-child(3n+3) {
          &::after {
            display: none;
          }
        }
      }
      &.grid--xsmall {
        > *:nth-child(5n+5) {
          &::after {
            display: none;
          }
        }
      }
      &.grid--small {
        > *:nth-child(4n+4) {
          &::after {
            display: none;
          }
        }
      }
      &.grid--large {
        > *:nth-child(even) {
          &::after {
            display: none;
          }
        }
      }
    }
  }

  // don't match heights on grid items
  &.grid--no-stretch {
    align-items: start;
  }

}

// ==============================================
// grids inside a sidebar layout

// stack grid items when in the sidebar column
.grid.sidebar .grid__sidebar {
  .grid {
    > * {
      flex-basis: 100%;
    }
    @supports(display: grid) {
      grid-template-columns: 1fr;
    }

    //
    &.grid--ruled,
    &.grid--ruled:not(.grid--xsmall):not(.grid--small):not(.grid--large):not(.grid--xlarge) {
      @include break-min($break-small) {
        > * {
          &:after {
            @include ruled-grid-line;
          }
          &:last-child {
            &:after {
              display: none;
            }
          }
        }
        &.grid--narrow-gutters {
          > * {
            &:after {
              @include ruled-grid-line($gap: $grid-gap-narrow);
            }
            &:last-child {
              &:after {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

// ==============================================
// scheming
@each $scheme-name, $scheme in $schemes {
  @include scheme-pattern($scheme-name, $scheme) {
    .grid.grid--ruled {
      > * {
        &:after {
          background-color: color(utility-line, $scheme);
        }
      }
    }
  }
}