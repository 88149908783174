.pullquote {
  @include pattern-stack-margin($margin: component-spacing(s));
  .pullquote__quote {
    & > :first-child {
      &:before {
        content: "“";
      }
    }
    & > :last-child {
      &:after {
        content: "”";
      }
    }
    p {
      margin-bottom: component-spacing(xs);
    }
  }
}