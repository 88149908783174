body {
  margin: 0;
  padding: 0;
  font-family: $font-primary;
  overflow-x: hidden;
}

// Prep images to be responsive
img {
  display: block;
  width: 100%;
  height: auto;
}

// Hide component from sighted users,
// maintain for screen readers
// show-for-sr is used in most cases,
// `invisible` is used by the modal JS library
.show-for-sr,
.invisible,
.visually-hidden {
  @include visually-hidden;
}
.show-for-none {
  display: none;
}

code {
  // have to make this text slightly smaller than the surround to keep from
  // interfering with the line height.
  font-size: .8em;
}

// common scheme things across all patterns
@each $scheme-name, $scheme in $schemes {
  @include scheme-pattern($scheme-name, $scheme) {
    background-color: color(background, $scheme);
    color: color(text, $scheme);
  }
}

@each $scheme-name, $scheme in $schemes {
  @include scheme-pattern($scheme-name, $scheme) {
    [class*=texture-grid],
    &[class*=texture-grid] {
      &:after {
        opacity: scheme-value(texture-grid-opacity, $scheme);
        @if scheme-value(texture-grid-invert, $scheme) {
          background-image: image-url('texture-grid-invert.svg');
        }
      }
    }
  }
}