// Initial styles for .nice-video assume no javascript

.nice-video {
  position: relative;
}

.nice-video--control,
.button.nice-video--control,
button.nice-video--control {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  border-radius: 50%;
  opacity: 0.8;
  &:hover,
  &:active,
  &:focus {
    opacity: 1;
  }
  .icon {
    .icon__glyph {
      margin: 0;
      font-size: rem(30px);
      line-height: 1em;
      // triangle icon looks off-center even though it isn't:
      margin-right: rem(-8px);
      padding: component-spacing(2xs);
    }
    .icon__label {
      @include visually-hidden;
    }
  }
}

// ==============================================
// scheming
@each $scheme-name, $scheme in $schemes {
  @include scheme-pattern($scheme-name, $scheme) {
    .nice-video {
      .nice-video--control {
        // background-color: color(button-text, $scheme);
      }
    }
  }
}