figure {
  margin: 0;
  @include pattern-stack-margin;

  img {
    display: block;
    width: 100%;
    height: 100%;
    font-family: "object-fit: contain; object-position: top center";
    object-position: top center;
    object-fit: contain;
  }

  figcaption {
    border-width: $utility-line-thickness;
    border-style: solid;
    border-top: 0;
    padding: component-spacing(s);
    p {
      margin-top: 0;
      margin-bottom: component-spacing(xs);
      &:last-child {
        margin-bottom: 0;
      }
    }
    cite {
      display: block;
      // margin-top: component-spacing(s);
      // margin-bottom: component-spacing(s);
    }
    p + cite {
      margin-top: 0;
    }
  }
}

figure,
figure figcaption,
figure figcaption p,
figure figcaption cite {
  @include rr-font-compute(xs, m);
  font-family: $font-primary;
}


// ==============================================
// scheming
@each $scheme-name, $scheme in $schemes {
  @include scheme-pattern($scheme-name, $scheme) {
    figure {
      figcaption {
        border-color: color(utility-line, $scheme);
      }
    }
  }
}
// allow the scheme to be applied to the figure itself, too
@each $scheme-name, $scheme in $schemes {
  @include scheme-pattern($scheme-name, $scheme, 'figure', true) {
    figcaption {
      border-color: color(utility-line, $scheme);
    }
  }
}