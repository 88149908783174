hr {
  border-width: 0 0 $accent-line-thickness;
  margin-top: component-spacing(s);
  margin-bottom: component-spacing(s);
}

@each $scheme-name, $scheme in $schemes {
  @include scheme-pattern($scheme-name, $scheme) {
    hr {
      border-color: color(utility-line, $scheme);
    }
  }
}