.person {
  .person__main {
    .person__content {
      .headline-group {
        @include text-simple-heading;
        .headline-group__head {
          @include text-simple-heading;
        }
        .headline-group__sub {
          font-style: italic;
          font-family: $font-primary;
        }
      }
      p.person__location {
        font-weight: bold;
        margin-bottom: component-spacing(xs);
      }
      p,
      .headline-group {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .person__image {
      @include break-max($break-medium) {
        margin-top: component-spacing(m);
      }
      figure {
        margin-bottom: 0;
      }
    }
  }

  .person__footer {
    border-top-width: $accent-line-thickness;
    border-top-style: solid;
    margin-top: component-spacing(m);
    padding-top: component-spacing(m);
  }

  @include break-min($break-xsmall) {
    .person__main {
      display: flex;
      flex-flow: row nowrap;
      .person__content {
        flex: 1 1 auto;
      }
      .person__image {
        flex: 0 0 30%;
        margin-left: component-spacing(m);
      }
    }
  }
}

// ==============================================
// sidebar behavior
// treat floats in narrow-centered page like a sidebar, in this case
.grid.sidebar .grid__sidebar,
.slab--narrow-center [class*=float--] {
  .person {
    .person__main {
      .person__content {
        .headline-group {
          @include rr-font-compute(s, m);
          .headline-group__head {
            @include rr-font-compute(s, m);
          }
          .headline-group__sub {
            @include rr-font-compute(xs, m);
          }
        }
      }
    }
  }
}

// ==============================================
// scheming
@each $scheme-name, $scheme in $schemes {
  @include scheme-pattern($scheme-name, $scheme) {
    .person {
      .person__footer {
        border-top-color: color(utility-line, $scheme);
      }
    }
  }
}