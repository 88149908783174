.term {
  @include pattern-stack-margin;

  .term__term {
    margin-bottom: component-spacing(2xs);
  }
  .term__definition {
    & > * {
      margin-top: 0;
    }
  }
}

// display the term inline with the definition
// auto add a colon
// apply individually or to a parent list
.list--inline-terms .term,
.term.term--inline {
  .term__term,
  .term__definition {
    display: inline;
  }
  .term__term {
    &:after {
      content: ': ';
    }
  }
}

// emphasize the term to improve readability
// apply individually or to a parent list
.list--emphasize-terms .term,
.term.term--emphasize-term {
  .term__term {
    font-weight: bold;
  }
}

.list--inline-terms {
  @include pattern-stack-margin;
}

// when an inline term appears in an inline list,
// make the whole business inline, not just the term+definition
.list--inline,
.list--series,
.list--piped,
.list--slash {
  .term--inline {
    display: inline;
  }
  &.list--inline-terms {
    .term {
      display: inline;
    }
  }
}
