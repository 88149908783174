.alert {
  // more tbd possibly
}


// ==============================================
// scheming
@each $scheme-name, $scheme in $schemes {
  @include scheme-pattern($scheme-name, $scheme) {
    .alert {
      .icon--circle {
        .icon__glyph {
          &:after {
            background-color: color(accent-text, $scheme);
          }
        }
      }
      &.alert--warning {
        .icon--circle {
          .icon__glyph {
            &:after {
              background-color: color(warning, $scheme);
            }
          }
        }
      }
      &.alert--emergency {
        .icon--circle {
          .icon__glyph {
            &:after {
              background-color: color(error, $scheme);
            }
          }
        }
      }
      &.alert--clear {
        .icon--circle {
          .icon__glyph {
            &:after {
              background-color: color(valid, $scheme);
            }
          }
        }
      }
    }
  }
}