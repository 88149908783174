cite {
  $dash-offset: rem(8px);
  $dash-gap: rem(3px);

  display: block;
  position: relative;
  font-family: $font-secondary;
  font-style: normal;
  @include rr-font-compute(xs, m);
  margin-left: $dash-offset + $dash-gap;
  .title {
    &:before {
      display: inline-block;
      content: '-';
      margin-left: -$dash-offset;
      margin-right: $dash-gap;
    }
  }
  .description {
    // nothing for now
  }

  &.cite--dashless {
    margin-left: 0;
    .title {
      &:before {
        display: none;
      }
    }
  }

  &.cite--fancy {
    font-style: italic;
  }
}