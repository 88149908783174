/* This file has been generated from a template. Do not edit it directly, your edits will 
   be ignored like a ten-year-old's dad. Instead, edit the source template in 
   /assets/font-svg/_iconfont-classes.scss. 

   This is a lodash template.
*/

.ic:before {
  display: inline-block;
  font-family: "iconfont";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ic--lg {
  font-size: 1.3333333333333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}

.ic--2x {
    font-size: 2em;
}

.ic--3x {
    font-size: 3em;
}

.ic--4x {
    font-size: 4em;
}

.ic--5x {
    font-size: 5em;
}

.ic--fw {
    width: 1.2857142857142858em;
    text-align: center;
}


.ic--angle-left:before {
    content: "#{$icon-angle-left}";
}
    
.ic--angle-right:before {
    content: "#{$icon-angle-right}";
}
    
.ic--arrow-circle-right:before {
    content: "#{$icon-arrow-circle-right}";
}
    
.ic--arrow-right:before {
    content: "#{$icon-arrow-right}";
}
    
.ic--audio:before {
    content: "#{$icon-audio}";
}
    
.ic--bolt:before {
    content: "#{$icon-bolt}";
}
    
.ic--camera:before {
    content: "#{$icon-camera}";
}
    
.ic--check:before {
    content: "#{$icon-check}";
}
    
.ic--clock:before {
    content: "#{$icon-clock}";
}
    
.ic--close:before {
    content: "#{$icon-close}";
}
    
.ic--cog-box:before {
    content: "#{$icon-cog-box}";
}
    
.ic--envelope:before {
    content: "#{$icon-envelope}";
}
    
.ic--event:before {
    content: "#{$icon-event}";
}
    
.ic--exclamation-circle:before {
    content: "#{$icon-exclamation-circle}";
}
    
.ic--exclamation-triangle:before {
    content: "#{$icon-exclamation-triangle}";
}
    
.ic--exclamation:before {
    content: "#{$icon-exclamation}";
}
    
.ic--external:before {
    content: "#{$icon-external}";
}
    
.ic--facebook-block:before {
    content: "#{$icon-facebook-block}";
}
    
.ic--facebook:before {
    content: "#{$icon-facebook}";
}
    
.ic--hamburger:before {
    content: "#{$icon-hamburger}";
}
    
.ic--home:before {
    content: "#{$icon-home}";
}
    
.ic--info:before {
    content: "#{$icon-info}";
}
    
.ic--instagram-block:before {
    content: "#{$icon-instagram-block}";
}
    
.ic--instagram:before {
    content: "#{$icon-instagram}";
}
    
.ic--iv-bag:before {
    content: "#{$icon-iv-bag}";
}
    
.ic--linkedin-block:before {
    content: "#{$icon-linkedin-block}";
}
    
.ic--linkedin:before {
    content: "#{$icon-linkedin}";
}
    
.ic--magnify:before {
    content: "#{$icon-magnify}";
}
    
.ic--map-pin-line:before {
    content: "#{$icon-map-pin-line}";
}
    
.ic--news:before {
    content: "#{$icon-news}";
}
    
.ic--pause:before {
    content: "#{$icon-pause}";
}
    
.ic--pdf:before {
    content: "#{$icon-pdf}";
}
    
.ic--phone:before {
    content: "#{$icon-phone}";
}
    
.ic--pin:before {
    content: "#{$icon-pin}";
}
    
.ic--pinterest-block:before {
    content: "#{$icon-pinterest-block}";
}
    
.ic--pinterest:before {
    content: "#{$icon-pinterest}";
}
    
.ic--play-triangle:before {
    content: "#{$icon-play-triangle}";
}
    
.ic--plus-circle:before {
    content: "#{$icon-plus-circle}";
}
    
.ic--popup:before {
    content: "#{$icon-popup}";
}
    
.ic--quote-close:before {
    content: "#{$icon-quote-close}";
}
    
.ic--quote-open:before {
    content: "#{$icon-quote-open}";
}
    
.ic--rss:before {
    content: "#{$icon-rss}";
}
    
.ic--snapchat-block:before {
    content: "#{$icon-snapchat-block}";
}
    
.ic--snapchat:before {
    content: "#{$icon-snapchat}";
}
    
.ic--star-empty:before {
    content: "#{$icon-star-empty}";
}
    
.ic--star-filled:before {
    content: "#{$icon-star-filled}";
}
    
.ic--star-half:before {
    content: "#{$icon-star-half}";
}
    
.ic--stethoscope:before {
    content: "#{$icon-stethoscope}";
}
    
.ic--twitter-block:before {
    content: "#{$icon-twitter-block}";
}
    
.ic--twitter:before {
    content: "#{$icon-twitter}";
}
    
.ic--video:before {
    content: "#{$icon-video}";
}
    
.ic--youtube-block:before {
    content: "#{$icon-youtube-block}";
}
    
.ic--youtube:before {
    content: "#{$icon-youtube}";
}
    