/* double up the class for greater specificity */
.social-media-share-buttons.social-media-share-buttons {
  ul {
    @include clean-list;
    display: flex;
    flex-flow: row wrap;

    li {
      margin-bottom: component-spacing(2xs);
      margin-right: component-spacing(2xs);
      &:last-child {
        margin-right: 0;
      }

      a, button {
        @include faux-underline($underline-color: transparent);
        display: inline-block;
        background-color: transparent !important;
        padding: 0;
        border: none;

        &:hover,
        &:active,
        &:focus {
          text-decoration: none;
        }
        .icon {
          .icon__glyph {
            transition: color $transition-time $transition-easing;
            position: relative;
            width: $social-media-circle-size;
            height: $social-media-circle-size;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-size: $social-media-circle-size/2;
            margin-right: 0;

            &:before {
              position: relative;
              z-index: 2;
            }
            &:after {
              content: '';
              display: inline-block;
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              margin: auto;
              z-index: 1;
              border-radius: 50%;
              transition: background-color $transition-time $transition-easing;
            }
            // some of the icons have funky size ratios and need tweaking.
            // if the icons are changed in the future, this will need to be adjusted.
            &.ic--youtube,
            &.ic--twitter {
              font-size: rem(12px);
            }
            &.ic--mail {
              font-size: rem(10px);
            }
            &.ic--youtube {
              &:before {
                margin-top: rem(1px);
              }
            }
            &.ic--twitter {
              &:before {
                margin-top: rem(2px);
              }
            }
          }

          .icon__label {
            @include visually-hidden;
          }
        }
      }
    }
  }
}

// ==============================================
// scheming
@each $scheme-name, $scheme in $schemes {
  @include scheme-pattern($scheme-name, $scheme) {
    .social-media-share-buttons {
      ul {
        li {
          a, button {
            .icon {
              .icon__glyph {
                color: color(background, $scheme);
                &:after {
                  background-color: color(link-text, $scheme);
                }
              }
            }
            &:hover,
            &:active,
            &:focus {
              .icon {
                .icon__glyph {
                  color: color(background, $scheme);
                  &:after {
                    background-color: color(link-hover-text, $scheme);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}