// Creates a slab for a slab layout. 
// Layout options are provided in other mixins
// All options are driven by the global configuration
// Slabs bring their own padding to the party which 
// we will override in some special circumstances.
@mixin rr-slab {
  $breaks: rr-breakpoint-list();
  @each $break in $breaks {
    $vspace: rr-gridbase(rr-prop-for-breakpoint($break, vspace));
    @if $break == nth($breaks, 1) {
      padding: $vspace rr-prop-for-breakpoint($break, offset);
    } @else {
      @include rr-break-directive($break) {
        padding: $vspace rr-prop-for-breakpoint($break, offset);
      }
    }
  }
}

// This creates a slab wrapper constrained to the main content width
@mixin rr-slab-wrapper($margins: false) {
  max-width: $rr-maxwidth;
  margin: 0 auto;
  @if not $margins {

    // Get rid of top margins on the first child,
    // bottom margins on the second so those
    // margins don't pop out of the wrapper.
    > :first-child {
      margin-top: 0;
    }

    > :last-child {
      margin-bottom: 0;
    }
  }
}

// This creates a full-bleed slab wrapper
@mixin rr-slab-full-fullbleed {
  max-width: none;
  margin: 0;

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }
}

// Pass this a list of class names (without the '.' prefix) and 
// adjacency classes that collapse padding between the 
@mixin rr-slab-adjacency($style-list) {
  @each $style in $style-list {
    .#{$style}+.#{$style} {
      padding-top: 0;
    }
  }
}

@function rr-columns-for-template($template, $break) {
  @if map-has-key($template, $break) {
    $break-template: map-get($template, $break);
    @if map-has-key($break-template, columns) {
      @return map-get($break-template, columns);
    }
  }
  @return null;
}

@function rr-areas-for-template($template, $break) {
  @if map-has-key($template, $break) {
    $break-template: map-get($template, $break);
    @if map-has-key($break-template, areas) {
      @return map-get($break-template, areas);
    }
  }
  @return null;
}

@function rr-flex-areas-for-template($template, $break) {
  @if map-has-key($template, $break) {
    $break-template: map-get($template, $break);
    @if map-has-key($break-template, flex-areas) {
      @return map-get($break-template, flex-areas);
    }
  }
  @return null;
}

@function rr-flex-columns-for-template($template, $break) {
  @if map-has-key($template, $break) {
    $break-template: map-get($template, $break);
    @if map-has-key($break-template, flex-columns) {
      @return map-get($break-template, flex-columns);
    }
  }
  @return null;
}
